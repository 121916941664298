/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import { NavLink } from 'react-router-dom';
import { PSNavigationBuilderStyle } from './PSNavigationBuilder.css';
import { isPathMatching } from '../../utils';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';

type TNavigationSection = {
    type: 'section';
    items: Array<TNavigationItem>;
}

type TNavigationCategory = {
    type: 'category';
    collapseIcon: IconNames;
    name: string;
    items: TNavigationItem[];
}

type TNavigationItemBase = {
    type: 'item';
    icon: IconNames;
    label: string;
};

type TNavigationItemWithPath = TNavigationItemBase & {
    path: string;
    isSelectedPaths?: Array<string>;
    onClick?: never;
};

type TNavigationItemWithOnClick = TNavigationItemBase & {
    onClick: () => void;
    path?: never;
    isSelectedPaths?: never;
};

type TNavigationItem = TNavigationItemWithPath | TNavigationItemWithOnClick;

type TNavigationEntity = TNavigationSection | TNavigationCategory | TNavigationItem;

export type TNavigationMenu = TNavigationEntity[];

type IProps = {
    navigation: TNavigationMenu;
    currentPath: string;
    isCollapsed?: boolean;
};

const PSNavigationBuilder: React.FC<IProps> = (props) => {
    const { navigation, isCollapsed, currentPath } = props;

    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>({});

    const toggleCategory = (categoryName: string) => {
        setCollapsedCategories(prev => ({
            ...prev,
            [categoryName]: !prev[categoryName]
        }));
    };

    return (
        <div>
            {navigation.map((entity, index) => (
                <React.Fragment key={index}>
                    {entity.type === 'section' && (
                        <>
                            <div css={{ display: 'flex', flexDirection: 'column' }}>
                                {entity.items.map(x => <PSNavigationItem key={x.path} item={x} currentPath={currentPath} isCollapsed={isCollapsed} onClick={x.onClick} />)}
                            </div>
                            <hr css={PSNavigationBuilderStyle.hr(isCollapsed)} />
                        </>
                    )}

                    {entity.type === 'category' && (
                        <>
                            <div>
                                <div
                                    css={PSNavigationBuilderStyle.navCategory(isCollapsed)}
                                    onClick={() => toggleCategory(entity.name)}
                                >
                                    {!isCollapsed && <Text variant='small' color='black-30'>{entity.name}</Text>}
                                    {isCollapsed && <Icon color='black-30' iconSize={15} iconName={entity.collapseIcon} />}
                                    <Icon color='black-30' iconSize={15} iconName={collapsedCategories[entity.name] ? 'PSChevronDownIcon' : 'PSChevronUpIcon'} />
                                </div>
                                {!collapsedCategories[entity.name] && (
                                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                                        {entity.items.map(x => <PSNavigationItem key={x.path} item={x} currentPath={currentPath} isCollapsed={isCollapsed} onClick={x.onClick} />)}
                                    </div>
                                )}
                            </div>
                            <hr css={PSNavigationBuilderStyle.hr(isCollapsed)} />
                        </>
                    )}

                    {entity.type === 'item' && <PSNavigationItem item={entity} currentPath={currentPath} isCollapsed={isCollapsed} onClick={entity.onClick} />}
                </React.Fragment>
            ))}
        </div>
    );
};

type INavigationItemProps = {
    item: TNavigationItem;
    currentPath: string;
    isCollapsed?: boolean;
    onClick?: () => void;
}

export const PSNavigationItem: React.FC<INavigationItemProps> = React.memo(({ item, currentPath, isCollapsed, onClick }) => {
    const checkIfPathActive = useCallback((givenPath: string | undefined) => {
        if (!currentPath || !givenPath) return false;
        if (givenPath === '/') return currentPath === givenPath;
        return isPathMatching(currentPath, givenPath);
    }, [currentPath]);

    const isNavigationActive = useMemo(() => {
        return (item.isSelectedPaths || []).some(x => checkIfPathActive(x)) || checkIfPathActive(item.path);
    }, [checkIfPathActive, item.isSelectedPaths, item.path]);

    const renderContent = () => (
        <>
            <Icon color='white' iconSize={16} iconName={item.icon} />
            {!isCollapsed && <Text className='text'>{item.label}</Text>}
            <div className='active-indicator' />
        </>
    );

    const content = onClick ? (
        <div onClick={onClick} css={PSNavigationBuilderStyle.navLinkButton}>
            {renderContent()}
        </div>
    ) : 'path' in item ? (
        <NavLink
            to={item.path!}
            css={PSNavigationBuilderStyle.navLinkButton}
            className={clsx({ active: isNavigationActive })}
        >
            {renderContent()}
        </NavLink>
    ) : null;

    if (!content) return null;

    return isCollapsed ? (
        <Tooltip title={item.label} placement='right'>
            {content}
        </Tooltip>
    ) : content;
});

PSNavigationItem.displayName = 'PSNavigationItem';

export default PSNavigationBuilder;
