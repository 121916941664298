/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from "react";
import { SerializedStyles } from '@emotion/react';
import { PSComponentWithBadgeStyle } from './PSComponentWithBadge.css';
import {PSBadge} from "../index";
import {IProps as PSBadgeProps} from "../PSBadge/PSBadge";

type IProps = {
    children: ReactNode;
    badgeProps: PSBadgeProps;
    showBadge?: boolean;
    containerCss?: SerializedStyles;
    badgeCss?: SerializedStyles;
}

const PSComponentWithBadge: FC<IProps> = (props) => {
    const {children, badgeProps, containerCss, badgeCss, showBadge = true} = props;

    return (
        <div css={[PSComponentWithBadgeStyle.self, containerCss]}>
            {children}
            {showBadge && (
                <div css={[PSComponentWithBadgeStyle.badge, badgeCss]}>
                    <PSBadge {...badgeProps}/>
                </div>
            )}
        </div>
    )
}

export default PSComponentWithBadge;