/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { PSGenAIAddCardStyle } from './PSGenAIAddCard.css';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import clsx from 'clsx';
type IProps = {
    applicationName?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    isSelected?: boolean;
    isAppNotToTrack?: boolean;
    isAiComponent?: boolean;
    isCustom?: boolean;
    imageUrl?: string;
    onClick?: () => void;
};

const PSGenAIAddCard: React.FC<IProps> = (props) => {
    const { applicationName, isLoading, isDisabled, isSelected, isAppNotToTrack, isAiComponent, isCustom, imageUrl, onClick } = props;
    const [imageError, setImageError] = useState(false);

    if (isLoading) return (
        <Skeleton variant="rectangular" sx={{ borderRadius: '15px', minHeight: '70px' }} />
    )

    return (
        <button onClick={onClick} disabled={isDisabled} css={PSGenAIAddCardStyle.self} className={clsx({ 'disabled': isDisabled, 'isSelected': isSelected })}>
            <div>
                {!isCustom && (
                    imageUrl && !imageError ? (
                        <img
                            src={imageUrl}
                            width={40}
                            height={40}
                            alt="App Icon"
                            onError={() => setImageError(true)}
                        />
                    ) : (
                        <Icon iconName='PSDefaultAiApplicationIcon' color='black-70' iconSize={40} />
                    )
                )}
                {isCustom && <Icon iconName='PSDefaultAiApplicationIcon' iconSize={40} />}
            </div>
            <div css={PSGenAIAddCardStyle.textContainer}>
                <Text ellipsis variant='bold' color={isDisabled ? 'black-40' : 'black'}>{applicationName}</Text>
                {isAiComponent && <Text textCss={PSGenAIAddCardStyle.labelText} color={isDisabled ? 'black-40' : 'black'} variant='small'>AI Component</Text>}
            </div>
            <div css={PSGenAIAddCardStyle.iconContainer}>
                {isSelected && <Icon iconName="PSDoneFilledIcon" iconSize={20} color={isDisabled ? 'black-40' : 'purple-80'} />}
                {isAppNotToTrack && <Icon iconName="PSBlockIcon" iconSize={20} color='black-40' />}
            </div>
        </button>
    )
}

export default PSGenAIAddCard;