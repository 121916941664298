/** @jsxImportSource @emotion/react */
import React, { FC, useRef } from 'react';
import { ProtectionChipsStyle } from "./ProtectionChips.css";
import { ProtectionChip } from "../index";
import { Text, Tooltip } from "../../../index";
import { usePlusN } from "../../../../hooks";

type IProps = {
    chips: string[];
}

const ProtectionChips: FC<IProps> = (props) => {
    const {chips} = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        isLimitCalculated,
        entitiesToDisplay: chipsToDisplay,
        entitiesToHide: chipsToHide,
    } = usePlusN({entities: chips, containerRef, additionToEntityWidth: 20})

    return (
        <div css={ProtectionChipsStyle.self} ref={containerRef}>
            {isLimitCalculated && chipsToDisplay?.map((chip) => (<ProtectionChip value={chip} key={`${chip}-visible-protection-chips`}/>))}
            {chipsToHide?.length > 0 &&
                (<Tooltip
                    title={<Text>{chipsToHide?.join(', ')}</Text>}
                    placement='top'
                    arrow
                >
                    <span>{`+${chipsToHide.length}`}</span>
                </Tooltip>)}
        </div>
    )
}

export default ProtectionChips;