/** @jsxImportSource @emotion/react */
import { Radio, RadioProps } from '@mui/material';
import React from 'react';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import { PSRadioCardStyle } from './PSRadioCard.css';
import { ColorThemeKeys } from '../../styles';

type TPSRadioCardProps = {
    iconName: IconNames;
    title: string;
    description: string;
    isChecked?: boolean;
    selectedBorderColor?: ColorThemeKeys;
};

const PSRadioCardDefault: React.FC<TPSRadioCardProps> = (props) => {
    const { iconName, description, title, isChecked, selectedBorderColor = 'purple-60' } = props;
    return (
        <div css={[PSRadioCardStyle.self, isChecked && PSRadioCardStyle.selfChecked(selectedBorderColor)]}>
            <Icon iconName={iconName} color='black' iconSize={'medium'} />
            <Text variant="header2"><b>{title}</b></Text>
            <Text>{description}</Text>
        </div>
    )
}

const PSRadioCard: React.FC<RadioProps & TPSRadioCardProps> = (props) => {
    const { iconName, description, title, selectedBorderColor, ...radioProps } = props;

    const radioCardProps = {
        iconName,
        description,
        title,
        selectedBorderColor
    }

    return (
        <Radio
            disableRipple
            color='default'
            css={PSRadioCardStyle.radio}
            icon={<PSRadioCardDefault {...radioCardProps} />}
            checkedIcon={<PSRadioCardDefault isChecked {...radioCardProps} />}
            {...radioProps}
        />
    )
}

export default PSRadioCard;