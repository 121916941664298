/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TModes, TSizes } from "./types";

const getTextContainerMarginTop = (size: TSizes) => {
    switch (size) {
        case "large": return 45;
        case "medium": return 25;
        default: return 0;
    }
}

const getTextContainerGap = (size: TSizes) => {
    switch (size) {
        case "large": return 15;
        case "medium": return 10;
        default: return 0;
    }
}

const getGap = (mode: TModes, size: TSizes) => {
    if (mode === "vertical") return 0;
    if (size === "small") return 15;
    return 60;
}

export const PSEmptyStateStyles = {
    self: (mode: TModes, size: TSizes) => css`
        display: flex;
        flex-direction: ${mode === 'vertical' ? 'column' : 'row'};
        gap: ${getGap(mode, size)}px;
        justify-content: center;
        align-items: center;
        height: 100%;
    `,
    textContainer: (mode: TModes, size: TSizes) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${getTextContainerGap(size)}px;
        margin-top: ${mode === 'vertical' ? `${getTextContainerMarginTop(size)}px` : 0};
    `,
};