/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { pulse } from "../../../../../styles";

export const ProtectionCollapsibleStyle = {
  self: css`
    background-color: var(--color-white);
    border: 1px solid var(--color-black-30);
    border-radius: 20px;
    padding: 20px 30px;
    overflow-x: auto;

    &.disabled {
      background-color: var(--color-black-5);
    }

    transition: background-color 0.1s;
    
    container-type: inline-size;
    container-name: protectionContainer;
  `,
  collapsedContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  `,
  detailsContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    padding-left: 10px;
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
    flex-basis: min(40%, 200px);
    min-width: 110px;
  `,
  descriptionContainer: css`
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    min-width: 50px;
  `,
  actionsContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
  `,
  protectContainer: css`
    display: flex;
    align-items: stretch;
    gap: 10px;
    width: 365px;
  `,
  textTypesContainer: css`
    border-left: 1px solid var(--color-black-20);
    &:last-of-type {
      padding-right: 15px;
      border-right: 1px solid var(--color-black-20);
    }
    padding-left: 15px;
    min-width: 170px;
  `,
  expandedContainer: css`
    border-top: 1px solid var(--color-black-20);
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  `,
  circlePulse: css`
    animation: ${pulse()} 2s infinite;
  `,
  top10Container: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  `,
};
