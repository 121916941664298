/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Text from '../../../Text/Text';

type IProps = {
    value: string;
    formatter?: Function;
    maxWidth: number | string;
    minWidth?: number | string;
}

const ContentWrapper: FC<IProps> = (props) => {
    const {value, formatter, maxWidth, minWidth} = props;

    const displayedValue = formatter ? formatter(value) : value;

    return (
        <div style={{ maxWidth, minWidth }}>
            <span><Text ellipsis>{displayedValue}</Text></span>
        </div>
    )
}

export default ContentWrapper;