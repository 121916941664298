import { EChartOption } from 'echarts';

export const defaultTooltipFormatter = (params: EChartOption.Tooltip.Format) => {
    const { name, color, percent } = params;

    return `
        <div style="
            background: #040F2D;
            color: white;
            border-radius: 5px;
            padding: 10px;
        ">
            <div style="
                display: flex;
                align-items: center;
                gap: 5px;
            ">
                <span style="
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: ${color};
                    margin-right: 8px;
                    border-radius: 50%;
                "></span>
                <span style="
                font-family: "DM Mono";
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1px;
                text-transform: uppercase;
                ">${name}</span>
                <span style="font-weight: 400;"> (${percent}%)</span>
            </div>
        </div>
    `;
};

export const processData = (
    rawData: EChartOption.SeriesSunburst.DataObject[],
    minPercentage: number,
    shouldSort: boolean,
    maximumItemsCount: number
) => {
    if (!rawData.length) return [];

    const totalValue = rawData.reduce((sum, item) => sum + (item.value as number), 0);
    const threshold = totalValue * (minPercentage / 100);

    let filteredData = rawData.filter(item => (item.value as number) >= threshold);
    const othersValue = rawData.reduce((sum, item) =>
        (item.value as number) < threshold ? sum + (item.value as number) : sum, 0
    );

    if (othersValue > 0) {
        filteredData.push({
            value: othersValue,
            name: 'Others',
            itemStyle: { color: '#DDDEE3' }
        });
    }

    if (shouldSort) {
        filteredData.sort((a, b) => (b.value as number) - (a.value as number));
    }

    if (filteredData.length > maximumItemsCount) {
        const limitedData = filteredData.slice(0, maximumItemsCount);
        const excessValue = filteredData.slice(maximumItemsCount).reduce((sum, item) => sum + (item.value as number), 0);
        limitedData.push({
            value: excessValue,
            name: 'Others',
            itemStyle: { color: '#DDDEE3' }
        });
        filteredData = limitedData;
    }

    const adjustedData = filteredData.map(item => {
        const percentage = (item.value as number) / totalValue * 100;
        if (percentage < 1) {
            return {
                ...item,
                value: totalValue * 0.01,
                realValue: item.value
            };
        }
        return {
            ...item,
            realValue: item.value
        };
    });

    return adjustedData;
}; 