/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TextStyle } from "../../../Text/Text.css";

export const LineChartStyle = {
  lineChart: (minWidth: number | string, height: number | string) => css`
    position: relative;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(0, 1fr);

    max-width: 100%;
    min-width: ${typeof minWidth === "number" ? minWidth + "px" : minWidth};
    min-height: ${typeof height === "number" ? height + "px" : height};
    aspect-ratio: 4 / 1;
    
    & svg {
      overflow: visible;
    }

    &.others path[fill="var(--color-black-30)"] {
      cursor: pointer;
    }

    g > line {
        /* display: none; */
    }

    g > text {
        ${TextStyle.text}
        font-family: "DM Sans" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        color: var(--color-black-70) !important;
    }
  `,
};
