/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSTransferListStyle = {
    self: css`
    `,
    transferListContainer: css`
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        height: 400px;
    `,
    arrowsContainer: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    transferList: css`
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        border-radius: 10px;
        border: 1px solid var(--color-black-30);
        overflow: hidden;
        flex-basis: 300px;
    `,
    transferListHeader: css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        padding-left: 17px;
        border-bottom: 1px solid var(--color-black-30);
    `,
    transferListHeaderTitle: css`
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    `,
    transferListItems: css`
        display: flex;
        flex-direction: column;
        overflow: auto;
    `
}