/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const TokenRateLimitFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
            opacity: 1;
        }
    `
}