import _ from "lodash";
import {
  TConnectorsTypes,
  TProtection,
  TProtectionCategories,
} from "./protections_types";

const getProtectionByKey = <TConnector extends TConnectorsTypes, TType extends keyof TConnector, TKey extends keyof TConnector[TType]>
  (connector: TConnector, type: TType, key: TKey): TConnector[TType][TKey] | undefined => {
  return connector[type][key];
};

const hasCategory = <T extends TProtectionCategories>(
  obj: any
): obj is TProtection<T> => {
  return obj && typeof obj.category === "string";
};

const getProtectionsByCategory = <TConnector extends TConnectorsTypes, TType extends keyof TConnector, TCategory extends TProtectionCategories>(
  connector: TConnector, type: TType, category: TCategory
): Array<TConnector[TType][keyof TConnector[TType]]> => {
  const protections = connector[type];
  const result: Array<TConnector[TType][keyof TConnector[TType]]> = [];

  for (const key in protections) {
    const protection = protections[key];
    if (hasCategory(protection) && protection.category === category) {
      result.push(protection);
    }
  }

  return result;
};

const hasResponse = (connector: any): connector is { response: any } => {
  return "response" in connector;
};

const getProtectionStatuses = <
  TConnector extends TConnectorsTypes,
  TKey extends
  | keyof TConnector[keyof TConnector]
  | keyof (TConnector extends { response: any }
    ? TConnector["response"]
    : TConnector[keyof TConnector])
>(connector: TConnector, key: TKey): { prompt?: boolean; response?: boolean } => {
  const statuses: { prompt?: boolean; response?: boolean } = {};

  if (connector.prompt && key in connector.prompt) {
    const promptProtection =
      connector.prompt[key as keyof typeof connector.prompt];
    if (promptProtection && "enabled" in promptProtection) {
      statuses.prompt = promptProtection.enabled;
    }
  }

  if (
    hasResponse(connector) &&
    connector.response &&
    key in connector.response
  ) {
    const responseProtection =
      connector.response[key as keyof typeof connector.response];
    if (responseProtection && "enabled" in responseProtection) {
      statuses.response = responseProtection.enabled;
    }
  }

  return statuses;
};

const updateProtection = <TConnector extends TConnectorsTypes, TType extends keyof TConnector, TKey extends keyof TConnector[TType]>(
  connector: TConnector, type: TType, key: TKey, partialUpdate: Partial<TConnector[TType][TKey]>
): void => {
  if (type === "prompt" && (connector.prompt as any)[key]) {
    (connector.prompt as any)[key] = _.merge((connector.prompt as any)[key], partialUpdate);
  } else if (hasResponse(connector) && type === "response" && (connector.response as any)[key]) {
    (connector.response as any)[key] = _.merge((connector.response as any)[key], partialUpdate);
  }
};



const setProtectionStatus = <TConnector extends TConnectorsTypes, TType extends keyof TConnector, TKey extends keyof TConnector[TType]>
  (connector: TConnector, type: TType, key: TKey, status: boolean): void => {
  const protection =
    type === "prompt"
      ? (connector.prompt as any)[key]
      : hasResponse(connector) && type === "response"
        ? (connector.response as any)[key]
        : undefined;

  if (protection && "enabled" in protection) {
    protection.enabled = status;
  }
};

export const initializeConnectorAPI = <TConnector extends TConnectorsTypes>(connector: TConnector) => {
  return {
    getProtectionByKey: <TType extends keyof TConnector, TKey extends keyof TConnector[TType]>(
      type: TType,
      key: TKey
    ) => getProtectionByKey(connector, type, key),

    getProtectionsByCategory: <TType extends keyof TConnector, TCategory extends TProtectionCategories>(
      type: TType,
      category: TCategory
    ) => getProtectionsByCategory(connector, type, category),

    getProtectionStatuses: <TKey extends keyof TConnector[keyof TConnector]>(
      key: TKey
    ) => getProtectionStatuses(connector, key),

    updateProtection: <TType extends keyof TConnector, TKey extends keyof TConnector[TType]>(
      type: TType,
      key: TKey,
      partialUpdate: Partial<TConnector[TType][TKey]>
    ) => updateProtection(connector, type, key, partialUpdate),

    disableProtection: <TType extends keyof TConnector, TKey extends keyof TConnector[TType]>(
      type: TType,
      key: TKey
    ) => setProtectionStatus(connector, type, key, false),

    enableProtection: <TType extends keyof TConnector, TKey extends keyof TConnector[TType]>(
      type: TType,
      key: TKey
    ) => setProtectionStatus(connector, type, key, true),
  };
};

// EXAMPLE USAGE:

// const HomeGrownApplication: THomeGrownApplication = {
//   prompt: {
//     "Code Detector": {
//       allowed: [],
//       category: "general",
//       denied: [],
//       enabled: true,
//       threshold: 0.5,
//     },
//   },
//   response: {},
// };

// const HomeGrownApplicationAPI = initializeConnectorAPI(HomeGrownApplication);
// HomeGrownApplicationAPI.getProtectionByKey("prompt", "Code Detector");