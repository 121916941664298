/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { GenAiApplicationStyle } from './GenAiApplication.css';
import Text from '../../../Text/Text';
import Icon from '../../../Icon/Icon';
import Tooltip from "../../../Tooltip/Tooltip";

type IProps = {
    includeAppDomain?: boolean;
    iconSize?: number;
    maxWidth?: string | number;
    isIconOnly?: boolean;
} & Partial<CustomCellRendererProps>;

const GenAiApplication: React.FC<IProps> = (props) => {
    const { value, maxWidth, includeAppDomain = false, iconSize = 24, isIconOnly = false } = props;
    const [imageError, setImageError] = useState(false);

    const DefaultAiApplicationIcon = <Icon iconName='PSDefaultAiApplicationIcon' color='black-70' iconSize={iconSize} />;

    if (!value) return (
        <div css={GenAiApplicationStyle.self} style={{maxWidth}}>
            {DefaultAiApplicationIcon}
        </div>
    );

    const imageSrc = value.logo || value.faviconUrl;
    const isCustom = value.isCustom || value.verifiedOrCustom === 'Custom';

    const AppName = (<Text ellipsis={!isIconOnly}>{value.name}</Text>);

    const AppIcon = (
        <>
            {isCustom && <Icon iconName='PSDefaultAiApplicationIcon' iconSize={iconSize} />}
            {!isCustom && imageSrc && !imageError ? (
                    <img
                        width={iconSize}
                        height={iconSize}
                        src={imageSrc}
                        alt={value.name}
                        loading='lazy'
                        onError={() => setImageError(true)}
                    />
            ) : !isCustom && (
                DefaultAiApplicationIcon
            )}
        </>
    );


    return (
        <div css={GenAiApplicationStyle.self} style={{maxWidth}}>
            {isIconOnly ? (<Tooltip title={AppName} placement='top' arrow><span style={{height: iconSize, width: iconSize}}>{AppIcon}</span></Tooltip>) : AppIcon}
            {value.name && !isIconOnly && AppName}
            {includeAppDomain && !isIconOnly && value.domain && <Text variant='small' color='black-70'>({value.domain})</Text>}
        </div>
    );
};

export default GenAiApplication;