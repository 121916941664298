/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { SerializedStyles } from '@emotion/react';
import { PieSvgProps, ResponsivePie } from '@nivo/pie';
import { PieChartStyle } from './PieChart.css';
import { randomBetween } from '../../../../utils';
import Text from '../../../Text/Text';
import clsx from 'clsx';
import Circle from '../../../Circle/Circle';

type DataItem = {
    id: string;
    label: string;
    value: number;
    percentage?: number;
    color?: string;
    details?: DataItem[];
} & Record<string, any>;

type IProps = {
    data: Array<DataItem>,
    size?: number | string;
    isLoading?: boolean;
    containerCss?: SerializedStyles;
    maxPieSlices?: number;
    customColors?: string[];
    sortByValue?: boolean;
} & Omit<PieSvgProps<DataItem>, 'height' | 'width'>;

const generateLoadingData = (numItems: number) => {
    return Array(numItems).fill(undefined).map((_, index) => ({
        id: index.toString(),
        label: 'Loading',
        value: Math.floor(Math.random() * 100) + 10,
        color: 'var(--color-black-30)',
    }));
}

const PieChart: React.FC<IProps> = (props) => {
    const { data, size = 0, isLoading, containerCss, maxPieSlices, customColors, sortByValue, ...pieProps } = props;
    const [loadingData, setLoadingData] = useState<Array<DataItem>>(generateLoadingData(5));
    const [othersData, setOthersData] = useState<DataItem | null>(null);
    const [restData, setRestData] = useState<Array<DataItem>>([]);
    const [dataToShow, setDataToShow] = useState<Array<DataItem>>(data);

    useEffect(() => {
        setDataToShow(data);
    }, [data])

    useEffect(() => {
        if (!maxPieSlices) return;
        if (data.length - 1 <= maxPieSlices) return;
        const sortedData = data.sort((a, b) => b.value - a.value);
        const slicedData = sortedData.slice(0, maxPieSlices);
        const restData = sortedData.slice(maxPieSlices);
        const restValue = restData.reduce((acc, item) => acc + item.value, 0);
        const othersData = {
            id: '_others_',
            label: `Others (+${restData.length})`,
            value: restValue,
            color: 'var(--color-black-30)',
            details: restData,
            percentage: Math.floor((restValue / data.reduce((acc, item) => acc + item.value, 0)) * 100),
        };

        setOthersData(othersData);
        setRestData(slicedData);
        setDataToShow([...slicedData, othersData]);
    }, [data, maxPieSlices]);

    useEffect(() => {
        if (!customColors) return;
        setDataToShow((prevData) => prevData.map((item, index) => ({
            ...item,
            color: item.id === '_others_' ? item.color : customColors[index % customColors.length],
        })));
    }, [data, customColors])

    useEffect(() => {
        if (!sortByValue) return;
        setDataToShow((prevData) => {
            const sortedData = prevData.filter(item => item.id !== '_others_').sort((a, b) => b.value - a.value);
            const othersData = prevData.find(item => item.id === '_others_');
            if (!othersData) return sortedData;
            return [...sortedData, othersData];
        });
    }, [data, sortByValue]);

    useEffect(() => {
        if (!isLoading) return;
        const colorInterval = setInterval(() => {
            setLoadingData((prevData) => prevData.map(item => ({ ...item, color: item.color === 'var(--color-black-30)' ? 'var(--color-black-40)' : 'var(--color-black-30)' })));
        }, 500);

        return () => {
            clearInterval(colorInterval);
        };
    }, [isLoading]);

    useEffect(() => {
        if (!isLoading) return;
        const dataInterval = setInterval(() => {
            setLoadingData(generateLoadingData(randomBetween(1, 10)));
        }, 2000);

        return () => {
            clearInterval(dataInterval);
        }
    }, [isLoading]);

    const blockPieInteractiveProps = {
        isInteractive: false,
        enableRadialLabels: false,
        enableSliceLabels: false,
        enableSlicesLabels: false,
    };

    if (isLoading) {
        return (
            <div>
                <div css={[PieChartStyle.pieChart(size), containerCss]}>
                    <ResponsivePie
                        {...pieProps}
                        {...blockPieInteractiveProps}
                        data={loadingData}
                    />
                </div>
                <div css={PieChartStyle.legendContainer}>
                    {Array(5).fill(undefined).map((_, index) => (
                        <div key={index} css={[PieChartStyle.legendItem, PieChartStyle.loadingContainer]}>
                            <Circle size={9} color='black-30' />
                            <div css={PieChartStyle.loadingText}></div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (dataToShow.length === 0) {
        return (
            <div css={[PieChartStyle.pieChart(size), containerCss]}>
                <ResponsivePie
                    {...pieProps}
                    {...blockPieInteractiveProps}
                    data={[
                        {
                            id: 'empty',
                            label: 'No data',
                            value: 100,
                            color: 'var(--color-black-30)',
                        }
                    ]}
                />
                <div css={PieChartStyle.noData}>
                    <Text color='black'>No Data</Text>
                    <Text variant='small' color='black-40'>No data to display</Text>
                </div>
            </div>
        );
    };


    return (
        <div>
            <div css={[PieChartStyle.pieChart(size), containerCss]} className={clsx({ 'others': othersData })}>
                <ResponsivePie
                    {...pieProps}
                    data={dataToShow}
                />
            </div>
            <div css={PieChartStyle.legendContainer}>
                {dataToShow.map((dataItem) => (
                    <div key={dataItem.id} css={PieChartStyle.legendItem}>
                        <Circle size={9} nativeColor={dataItem.color} />
                        <Text color='black'>{dataItem.label}</Text>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PieChart;