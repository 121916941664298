/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { useDropzone, Accept, FileRejection } from 'react-dropzone';
import { FileUploadInputStyle } from './FileUploadInput.css';
import { fileSizeFormatter } from '../../../utils/number';
import Icon from '../../Icon/Icon';
import PSButton from '../../PSButton/PSButton';
import Text from '../../Text/Text';

type SingleFileUploadProps = {
    multiple: false;
    onFileChange: (file: File | null) => void;
    initialFile?: File | null;
};

type MultiFileUploadProps = {
    multiple: true;
    onFileChange: (files: File[]) => void;
    initialFiles?: File[];
};

type CommonProps = {
    isLoading?: boolean;
    maxSize?: number;
    acceptedFileTypes?: Accept;
    showPreview?: boolean;
};

type FileUploadProps = (SingleFileUploadProps | MultiFileUploadProps) & CommonProps;

const FileUploadInput: React.FC<FileUploadProps> = (props) => {
    const {
        isLoading = false,
        maxSize,
        acceptedFileTypes,
        showPreview = false,
        multiple,
        onFileChange,
    } = props;

    const [files, setFiles] = useState<File[]>(
        multiple ? (props.initialFiles || []) : (props.initialFile ? [props.initialFile] : [])
    );
    const [previews, setPreviews] = useState<string[]>([]);

    const updatePreviews = (newFiles: File[]) => {
        if (showPreview) {
            Promise.all(
                newFiles.map(file =>
                    file.type.startsWith('image/')
                        ? new Promise<string>((resolve) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result as string);
                            reader.readAsDataURL(file);
                        })
                        : Promise.resolve('')
                )
            ).then(setPreviews);
        }
    };

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (isLoading) return;

        let newFiles: File[];
        if (multiple) {
            newFiles = [...files, ...acceptedFiles];
        } else {
            newFiles = acceptedFiles.slice(0, 1);
        }

        setFiles(newFiles);
        updatePreviews(newFiles);

        if (multiple) {
            (onFileChange as MultiFileUploadProps['onFileChange'])(newFiles);
        } else {
            (onFileChange as SingleFileUploadProps['onFileChange'])(newFiles[0] || null);
        }
        
    }, [files, isLoading, multiple, onFileChange, showPreview]);

    const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        maxSize,
        multiple,
    });

    const removeFile = (index: number) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
        updatePreviews(newFiles);

        if (multiple) {
            (onFileChange as MultiFileUploadProps['onFileChange'])(newFiles);
        } else {
            (onFileChange as SingleFileUploadProps['onFileChange'])(newFiles[0] || null);
        }
    };

    return (
        <div css={FileUploadInputStyle.self}>
            <div {...getRootProps()} css={[
                FileUploadInputStyle.dropFileContainer,
                isDragAccept && FileUploadInputStyle.draggingOverStyle,
                isDragReject && FileUploadInputStyle.fileNotAllowedStyle
            ]}>
                <input {...getInputProps()} disabled={isLoading} />
                {!isDragActive &&
                    <div css={FileUploadInputStyle.dropTextContainer}>
                        <Icon iconName='PSUploadCloudIcon' color='black-40' iconSize={40} />
                        <div css={FileUploadInputStyle.dropTextExplanation}>
                            <Text color='black-70'>Drop {multiple ? 'files' : 'a file'} here, or click to upload</Text>
                            {maxSize && <Text color='black-70' variant='small'>{fileSizeFormatter.format(maxSize)} limit per file</Text>}
                        </div>
                    </div>
                }
                {isDragAccept && <Text color='black-70'>File{multiple ? 's' : ''} accepted, drop {multiple ? 'them' : 'it'}!</Text>}
                {isDragReject && <Text color='black-70'>File type not accepted, please upload valid file{multiple ? 's' : ''}</Text>}
            </div>
            {files.map((file, index) => (
                <div key={`${file.name}-${index}`} css={FileUploadInputStyle.previewContainer}>
                    <div css={FileUploadInputStyle.fileDetailsContainer}>
                        <Icon iconName='PSFileIcon' color='black-40' iconSize={40} />
                        <div css={FileUploadInputStyle.fileDetails}>
                            <Text color='black-70'>{file.name}</Text>
                            <Text color='black-70' variant='small'>{fileSizeFormatter.format(file.size)}</Text>
                        </div>
                        {!isLoading && (
                            <PSButton
                                variant='flat'
                                onClick={() => removeFile(index)}
                                css={FileUploadInputStyle.deleteFileButton}
                                iconName='PSRemoveIcon'
                            >
                                Remove File
                            </PSButton>
                        )}
                    </div>
                    {showPreview && previews[index] && < img src={previews[index]} alt={`Preview ${index}`} style={{ objectFit: 'contain', maxWidth: 200, aspectRatio: '2 / 1' }} />}
                </div>
            ))}
        </div>
    );
}

export default FileUploadInput;