/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PlaygroundFileStyle = {
  formContainer: css`
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px;
      border: 1px solid var(--color-black-30);
      background: var(--color-white);
      border-radius: 20px;
  `,
  testButton: css`
    align-self: flex-start;
  `,
  resultContainer: css`
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 30px;
      border-radius: 20px;
      border: 1px solid var(--color-black-30);
      box-shadow: 0px 0px 25px 0px var(--color-black-20);
      background: var(--color-white);
  `,
  sanitizedFileOutputTextField: css`
    & .MuiInputBase-root {
        background: var(--color-black-5);

        & fieldset {
            border: none;
        }
    }
  `,
}