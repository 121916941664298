/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {TBadgeSize} from './types'
import {ColorThemeKeys} from "../../styles";

const getPaddingValue = (size: TBadgeSize) => {
    switch (size) {
        case "large": return '5px'
        case "medium": return '2px 5px'
        default: return '0 3px'
    }
}

const getFontSize = (size: TBadgeSize, label: string | undefined) => {
    if (!label) return ''
    let fontSize = '';
    switch (size) {
        case "large":
            fontSize = '15px;'
            break;
        case "medium":
            fontSize = '12px;'
            break;
        default: fontSize = '10px;'
    }

    return css`
        font-size: ${fontSize};    
    `;
}

export const BadgeStyles = {
    self: (size: TBadgeSize, backgroundColor: ColorThemeKeys, label: string | undefined) => css`
        background-color: var(--color-${backgroundColor});
        padding: ${getPaddingValue(size)};
        border-radius: 10px;
        display: flex;
        width: fit-content;
        text-wrap: nowrap;
        ${getFontSize(size, label)}
    `,
};