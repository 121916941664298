import { css } from "@emotion/react";
import { TextStyle } from "../ui-kit/Text/Text.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export const customAgGridTheme = css`
  &.ag-theme-quartz {
    --ag-active-color: var(--color-black);
    --ag-background-color: var(--color-white);
    /* --ag-borders: none; */
    --ag-row-border-width: 1px;
    --ag-row-border-color: var(--color-black-30);
    --ag-header-background-color: var(--color-white);
    --ag-wrapper-border-radius: 15px;

    --ag-font-size: 15px;
    --ag-font-family: "DM Sans";

    --ag-header-height: 50px;
    --ag-header-foreground-color: var(--color-black-40);
    --ag-header-background-color: var(--color-white);

    --ag-row-hover-color: var(--color-black-5);
    --ag-selected-row-background-color: var(--color-purple-5);

    --ag-cell-horizontal-padding: 20px;

    --ag-input-focus-box-shadow: none;

    & .ag-pinned-right-header {
      border-left: none;
      /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    }
    & .ag-pinned-left-header {
      border-right: none;
      /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    }
    & .ag-pinned-left-cols-container {
      border-right: none;
      /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
      z-index: 1;
    }
    & .ag-pinned-right-cols-container {
      border-left: none;
      /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    }

    & .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      border-left: none;
    }

    & .ag-header {
      border-bottom: 1px solid var(--color-black-20);
    }
    & .ag-header-group-cell {
      ${TextStyle.smallBold}
    }
    & .ag-header-cell {
      ${TextStyle.smallBold}
    }

    & .ag-body-vertical-scroll,
    & .ag-body-horizontal-scroll {
      background: var(--color-black-20);
    }


    & .ag-header-cell-text {
      overflow: unset;
      white-space: normal;
      word-wrap: break-word;
      text-wrap: pretty;
    }

    &:has(.ag-overlay:not(.ag-hidden)),
    &:has(.loading-skeleton) {
      & .ag-body-viewport {
        overflow: hidden;
      }
      & .ag-body-vertical-scroll,
      & .ag-body-horizontal-scroll {
        display: none;
      }
    }

    &:has(.clickable-rows) {
      & .ag-cell {
        cursor: pointer;
      }
    }

    & .ag-pinned-right-cols-container .ag-cell,
    & .ag-pinned-left-cols-container .ag-cell {
      cursor: revert;
    }

    & .ag-cell {
      ${TextStyle.text}
      height: 100%;
      color: var(--color-black-70);
      line-height: min(
        var(--ag-internal-calculated-line-height),
        var(--ag-internal-padded-row-height)
      );

      display: flex;
      align-items: center;

      & > * {
        margin: auto;
        margin-left: unset;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:has(.loading-skeleton) {
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .ag-row:last-child {
      border-bottom: none;
    }
  }
`;
