import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

type NavigationBlockerActions = {
    saveAndProceed: () => Promise<void>;
    discardAndProceed: () => void;
    cancel: () => void;
    isLoading: boolean;
    isBlocked: boolean;
}

export const useNavigationBlocker = (
    when: boolean,
    onSave: () => Promise<void>
): NavigationBlockerActions => {
    const blocker = useBlocker(when);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (when) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [when]);

    const discardAndProceed = useCallback(() => {
        blocker?.proceed?.();
    }, [blocker]);

    const saveAndProceed = useCallback(async () => {
        await onSave();
        blocker?.proceed?.();
    }, [blocker, onSave]);

    const cancel = useCallback(() => {
        blocker?.reset?.();
    }, [blocker]);

    return {
        saveAndProceed,
        discardAndProceed,
        cancel,
        isBlocked: blocker.state === 'blocked',
        isLoading: blocker.state === 'proceeding'
    };
}; 