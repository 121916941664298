/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const FileUploadInputStyle = {
    self: css`
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    `,
    dropFileContainer: css`
        display: flex;
        align-items: center;
        flex: 1;
        background: var(--color-black-5);
        border-radius: 10px;
        border: dashed 1px var(--color-black-20);
        padding: 30px;
        cursor: pointer;
        transition: background 0.3s ease, border-color 0.3s ease, outline 0.3s ease;

        &:hover {
            border-color: var(--color-black);
        }

        &:active {
            background: var(--color-black-30);
        }
    `,
    dropTextContainer: css`
        display: flex;
        align-items: center;
        gap: 20px;
    `,
    dropTextExplanation: css`
        display: flex;
        flex-direction: column;
        gap: 5px;
    `,
    draggingOverStyle: css`
        border-color: var(--color-green-50);
    `,
    fileNotAllowedStyle: css`
        border-color: var(--color-red-50);
    `,
    fileDetailsContainer: css`
        display: flex;
        gap: 20px;
    `,
    fileDetails: css`
        display: flex;
        flex-direction: column;
        gap: 5px;
    `,
    deleteFileButton: css`
        align-self: flex-start;
        margin-left: auto;
    `,
    previewContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `
}