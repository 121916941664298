/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSRightOverDrawerStyle = {
    self: css`
    .MuiDrawer-paper {
      width: max(50%, min(600px, 80%));
      background: var(--color-black-10);
    }
  `,
    container: css`
    padding: 30px;
    padding-top: 0;
  `,
    title: css`
    margin-bottom: 40px;
  `,
    tabDataContainer: css`
    margin-top: 20px;
  `,
};