/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSPopperStyles = {
    self: css`
        width: fit-content;
        max-width: 100%;
    `,
    popper: css`
        background: var(--color-blue);
        color: var(--color-white);
        padding: 10px;
        border-radius: 5px;
        margin: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: var(--outer-elevation-4);
    `,
};
