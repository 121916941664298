/** @jsxImportSource @emotion/react */
import { ReactNode, FC } from 'react';
import { ColorThemeKeys } from "../../styles";
import {BadgeStyles} from './PSBadge.css';
import {TBadgeSize} from './types'
import {Text} from '../../ui-kit';
import {SerializedStyles} from "@emotion/react";

type IBaseBadgeProps = {
    size: TBadgeSize;
    backgroundColor: ColorThemeKeys;
    label?: string;
    children?: ReactNode;
    css?: SerializedStyles;
}

type TOnlyLabel = { label: string; children?: never }
type TOnlyChildren = { children: ReactNode; label?: never }

export type IProps =
    | (IBaseBadgeProps & TOnlyLabel)
    | (IBaseBadgeProps & TOnlyChildren)


const PSBadge: FC<IProps> = (props) => {
    const { children, label, size, backgroundColor, css } = props;
    return (<Text css={[BadgeStyles.self(size, backgroundColor, label), css]}>{children || label}</Text>)
}

export default PSBadge;
