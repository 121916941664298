/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSGenAIMenuCardStyle = {
    self: css`
        border-radius: 15px;
        border: 1px solid var(--color-black-30);
        background: var(--color-black-5);
        padding: 15px;
        cursor: pointer;
        user-select: none;

        display: flex;
        align-items: center;
        gap: 10px;

        &:not(.disabled) {
            &:hover {
            border-color: var(--color-black);
            }

            &.active {
                background-color: var(--color-white);
                border-color: var(--color-transparent);
                box-shadow: 0px 4px 10px 0px rgba(97, 54, 253, 0.10);
                border-color: var(--color-purple-80);
            }
        }

        &:focus-visible {
            outline: 2px solid var(--color-purple-50);
            outline-offset: -2px;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
            border-color: var(--color-black-40);
            color: var(--color-black-40);
            img {
                filter: grayscale(100%);
            }
        }
  `,
    textContainer: css`
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        overflow: hidden;
        max-width: 100%;
    `,
    labelText: css`
        background: var(--color-purple-20);
        padding-inline: 10px;
        border-radius: 15px;
    `,
    typeContainer: css`
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    `
}