/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const UserGroupsStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 350px;
    `,
    iconContainer: css`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    contentContainer: css`
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
    `,
    naWrapper: css`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
};