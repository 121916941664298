/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSPieChartStyle = {
    container: (height: number) => css`
        height: ${height}px;
        width: 100%; 
        position: relative;
    `,

    chartContainer: (height: number) => css`
        position: relative; 
        width: 100%; 
        height: ${height}px;
    `,

    centerContent: (height: number, chartWidth: number) => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(${Math.min(height, chartWidth) / height});
        text-align: center;
        pointer-events: none;
    `,

    loadingSkeleton: css`
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        gap: 10px;
    `
};