/** @jsxImportSource @emotion/react */
import React, { FC, useMemo, useRef, Fragment } from "react";
import { OWASPTopTenStyle } from './OWASPTopTen.css';
import { Chip, Text, Tooltip, PSPopper } from "../../../index";
import { AllProtectionKeys, LLMsTranslations, ViolationToTopTenMap } from "../../../../routes-new/Manage";
import { usePlusN } from "../../../../hooks";

type IProps = {
    violation: typeof AllProtectionKeys[number];
    keyString: string;
    gap?: number;
}

const OWASPTopTen: FC<IProps> = (props) => {
    const {violation, keyString, gap = 5} = props;
    const top10 = useMemo(() => violation ? ViolationToTopTenMap[violation] : [], [violation]);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        isLimitCalculated,
        entitiesToDisplay: top10ToDisplay,
        entitiesToHide: top10sToHide,
    } = usePlusN({entities: (top10 as string[]), containerRef})

    return (
        <div css={OWASPTopTenStyle.self(gap)} ref={containerRef}>
            {isLimitCalculated && top10ToDisplay?.map((item) => {
                const LLMsTranslationsKey = item.toLowerCase() as keyof typeof LLMsTranslations;

                return (
                    <Tooltip title={LLMsTranslations[LLMsTranslationsKey]} key={item} placement='top' disableInteractive arrow>
                        <div><Chip label={LLMsTranslationsKey} variant='outlined' size='small'/></div>
                    </Tooltip>
                )
            })}
            {top10sToHide && top10sToHide?.length > 0 &&
                (<PSPopper
                    key={`${keyString}-+N`}
                    tooltip={
                        <div css={OWASPTopTenStyle.plusNTooltip}>
                            {top10sToHide.map(item => (
                                <Fragment key={`${keyString}-${item}-hidden`}>
                                    <Text>{`${item}: `}</Text>
                                    <Text>{LLMsTranslations[item.toLowerCase() as keyof typeof LLMsTranslations]}</Text>
                                </Fragment>
                            ))}
                        </div>
                    }
                >
                    <span>{`+${top10sToHide?.length}`}</span>
                </PSPopper>)
            }
        </div>
    )
}

export default OWASPTopTen;