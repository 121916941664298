import { css } from '@emotion/react';

export const RoundedFlatButtonStyle = {
    self: css`
        border-radius: 30px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        border: none;
        max-width: 100%;
        
        color: var(--color-black);

        background: transparent;

        & .icon {
            width: 20px;
            height: 20px;
            fill: var(--color-black-40);
            transition: none;
        }

        &:disabled {
            color: var(--color-black-40);
            & .icon { fill: var(--color-black-40); }
            cursor: default;
        }

        :not(:disabled) {
            &:hover {
                background: var(--color-white);
            }
        
            &:active, &.active {
                background: var(--color-white);
                color: var(--color-purple-60);
                & .icon { fill: var(--color-purple-60); }
            }
        }
    `
}