/** @jsxImportSource @emotion/react */
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { TextStyle } from '../Text/Text.css';
import {isString} from "../../utils";

const Tooltip = styled(({ className, title, maxCharacters, maxWidth, ...props }: TooltipProps & { maxCharacters?: number, maxWidth?: number }) => {
    let displayedTitle = title;

    if (maxCharacters && displayedTitle && isString(displayedTitle) && displayedTitle.length > (maxCharacters)) {
        displayedTitle = displayedTitle.slice(0, maxCharacters) + '...';
    }

    return (
        <MuiTooltip {...props}
            classes={{ popper: className }}
            title={displayedTitle}
        />
    );
})`
    & .MuiTooltip-tooltip {
        background-color: var(--color-black);
        ${props => props.maxWidth ? `max-width: ${props.maxWidth}px;` : ''}
        ${TextStyle.text};
    }
    & .MuiTooltip-arrow {
        color: var(--color-black);
    }
`;

export default Tooltip;