import { Variables } from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useQuery, type UseQueryResult, type UseQueryOptions } from '@tanstack/react-query';
import { createGraphQLClient } from '../gql';

type UseGraphQLOptions<TResult, TVariables, TSelect = TResult> = {
    document: TypedDocumentNode<TResult, TVariables>;
    variables?: TVariables;
    onSuccess?: (data: TResult) => void;
    onError?: (error: Error) => void;
    select?: (data: TResult) => TSelect;
} & Omit<UseQueryOptions<TResult, Error, TSelect>, 'queryKey' | 'queryFn'>;

export const useGraphQL = <TResult, TVariables, TSelect = TResult>(
    { document, variables, onSuccess, onError, select, ...useQueryOptions }: UseGraphQLOptions<TResult, TVariables, TSelect>
): UseQueryResult<TSelect, Error> => {
    return useQuery<TResult, Error, TSelect>({
        queryKey: [(document.definitions[0] as any).name.value, variables],
        queryFn: async ({ queryKey, signal }) => {
            const client = createGraphQLClient(signal);
            try {
                const request = await client.request(document, queryKey[1] ? queryKey[1] as Variables : undefined);
                onSuccess?.(request);
                return request;
            } catch (error: any) {
                if (onError && error?.message !== 'Aborted') {
                    onError(error as Error);
                }
                throw new Error((error as Error).message);
            }
        },
        select,
        ...useQueryOptions
    });
};
