/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TextStyle } from '../Text/Text.css';

export const NavigationLinkItemExpandedStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 30px;
        padding: 8px 15px 8px 10px;
        cursor: pointer;
        user-select: none;
        position: relative;

        & .text { color: var(--color-black); }
        & .icon { fill: var(--color-black-40); }

        &:hover {
            & .text { font-weight: 700; color: var(--color-black); }
            & .icon { fill: var(--color-black); }
        }

        &:active {
            & .text { font-weight: 700; color: var(--color-black-70); }
            & .icon { fill: var(--color-black-70); }
            background: var(--color-white);
        }

        &.selected, 
        &.has-nested-active, 
        &:has([aria-current="page"]) {
            & .text { font-weight: 700; color: var(--color-black); }
            & .icon { fill: var(--color-black); }
            background: var(--color-white);
        }

        & .expand-icon {
            margin-left: auto;
        }

        &.collapsed {
            width: fit-content;
            align-self: center;
            padding: 8px 15px;

            & .expand-icon {
                position: absolute;
                right: 0;
            }
        }
    `,
    link: css`
        &:has(.collapsed) {
            align-self: center;
        }
    `,
    nested: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 10px;

        &.collapsed {
            border-left: 1px solid var(--color-black-40);
        }
    `,
    tooltip: css`
        .MuiTooltip-tooltip {
        background: var(--color-black);
            ${TextStyle.text}
        }
    `
}