import { DateTime } from "luxon";
import { TDateSelection } from '../contexts'

export const dateStringISOToFormatted = (dateString: string) => {
  return DateTime.fromISO(dateString).toFormat("LLL dd, yyyy | HH:mm:ss");
};

export const dateStringISOToFormattedDateOnly = (dateString: string) => {
  return DateTime.fromISO(dateString).toFormat("LLL dd, yyyy");
};
export const getDisplayedTimePeriodText = (dateContextObj: TDateSelection) => {
  const {period, dates} = dateContextObj;

  switch (period){
    case 'last7days': return '7 days';
    case 'last-month': return 'month';
    case 'last-year': return 'year';
  }

  const start = DateTime.fromJSDate(dates[0]);
  const end = DateTime.fromJSDate(dates[1]);

  const numberOfDays = Math.round(end.diff(start, 'days').as('days'));

  if (numberOfDays === 1) return 'day'
  return `${numberOfDays} days`
}
