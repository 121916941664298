/** @jsxImportSource @emotion/react */
import React, { createContext, useState } from 'react';

export type TopNavigationBarContextType = {
    topBarContent: boolean;
    setTopBarContent: React.Dispatch<React.SetStateAction<boolean>>;
    wrapperDomNode: HTMLElement | null;
    setWrapperDomNode: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

type IProps = {
    children: React.ReactNode;
};

export const TopNavigationBarContext = createContext<TopNavigationBarContextType | null>(null);

export const TopNavigationBarContextProvider: React.FC<IProps> = (props) => {
    const { children } = props;
    const [wrapperDomNode, setWrapperDomNode] = useState<HTMLElement | null>(null);
    const [topBarContent, setTopBarContent] = useState<boolean>(false);

    return (
        <TopNavigationBarContext.Provider value={{
            topBarContent,
            setTopBarContent,
            wrapperDomNode,
            setWrapperDomNode,
        }}>
            {children}
        </TopNavigationBarContext.Provider>
    )
}

export const useTopNavigationBarContext = () => {
    const context = React.useContext(TopNavigationBarContext);
    if (!context) {
        throw new Error('useTopNavigationBarContext must be used within a TopNavigationBarContextProvider');
    }
    return context;
}