/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const EmployeesExtensionSettingsStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
        flex: 1;
        padding-bottom: 20px;

        & .MuiFormControlLabel-root {
            min-width: 180px;
        }
    `,
    headerContainer: css`
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        position: sticky;
        top: 0;
        background: var(--color-white);
        z-index: 2;
    `,
    actionsContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    `,
    titleContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    formContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 30px;
    `,
    formRow: css`
        display: flex;
        align-items: center;
        gap: 30px;
    `,
    formRowLabelContainer: css`
        flex-basis: max(450px, 40%);
        min-width: 40%;
    `,
    buttonRow: css`
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        align-self: flex-end;
    `,
    buttonContainer: css`
        position: relative;
    `,
    removeButton: css`
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    `,
    rowCheckbox: css`
        display: flex;
        align-items: center;
        margin-left: -39px;
        margin-bottom: -9px;
    `,
    footer: css`
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-black-30);
        padding-top: 20px;
    `
}