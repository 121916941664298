import { css } from "@emotion/react";

export const TextStyleGlobal = {
  onClick: css`
    cursor: pointer;
    user-select: none;

    color: var(--color-purple-60);

    &:hover {
      color: var(--color-purple-70);
    }

    &:active {
      color: var(--color-purple-50);
    }
  `,
  onClickUnderline: css`
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
};

export const TextStyle = {
  bigHeader: css`
    font-family: "DM Sans";
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
  `,
  header: css`
    font-family: "DM Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  header2: css`
    font-family: "DM Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  header3: css`
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  text: css`
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  bold: css`
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  small: css`
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  smallBold: css`
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  mono: css`
    font-family: "DM Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
  monoSmall: css`
    font-family: "DM Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
};

export const SxTextStyle = {
  bigHeader: {
    fontFamily: "DM Sans",
    fontSize: 68,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0em",
  },
  header: {
    fontFamily: "DM Sans",
    fontSize: 30,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  header2: {
    fontFamily: "DM Sans",
    fontSize: 25,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  header3: {
    fontFamily: "DM Sans",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  text: {
    fontFamily: "DM Sans",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  bold: {
    fontFamily: "DM Sans",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  small: {
    fontFamily: "DM Sans",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  smallBold: {
    fontFamily: "DM Sans",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  mono: {
    fontFamily: "DM Mono",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
  monoSmall: {
    fontFamily: "DM Mono",
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
};
