/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PlaygroundTextFormStyle = {
  self: css`
    `,
  textFieldSubmitIndicator: css`
        position: absolute;
        bottom: 20px;
        right: 20px;
        pointer-events: none;
        color: var(--color-black-40);
        display: flex;
        align-items: center;
        gap: 5px;
  `,
  playgroundTextfield: css`
    .MuiInputBase-root {
      padding-bottom: 35px;
    }
  `
}