/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TextStyle } from '../Text/Text.css';

export const PSSegmentsSwitchStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 2px;
        position: relative;
        border-radius: 20px;
        background: var(--color-black-5);
        border: 1px solid var(--color-black-30);
        overflow-x: auto;
        max-width: fit-content;

        &:disabled {
            pointer-events: none;
        }
  `,
    option: css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 7px 10px;
        border: 1px solid var(--color-transparent);
        background: var(--color-transparent);
        white-space: nowrap;
        outline: none;
        cursor: pointer;

        &::before {
            content: attr(data-text);
            display: block;
            height: 0;
            opacity: 0;
            ${TextStyle.bold}
        }

        &:hover {
            background: var(--color-black-20);
        }

        &:focus-visible {
            border-color: var(--color-purple-20);
        }

        &:active {
            & .text { font-weight: 700; }
            background: var(--color-transparent);
            border-color: var(--color-purple-20);
        }

        &.isSelected {
            background: white;
            border-color: var(--color-black-30);
            box-shadow: var(--outer-elevation-1);
            & .text { font-weight: 700; }
        }

        &.isDisabled, &:disabled {
            color: var(--color-black-30);
            pointer-events: none;
        }

        &.isSelected.isDisabled, &.isSelected:disabled {
            background: white;
            border-color: var(--color-black-30);
            box-shadow: var(--outer-elevation-1);
            & .text { font-weight: 700; color: var(--color-black-30); }
            pointer-events: none;
        }
    `
};
