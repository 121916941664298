/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { IProtectionFormProps, TOKEN_LIMITATION_ENCODING_NAMES, TTokenRateLimitProtection, tokenLimitToValidNumber } from '../Common';
import { TokenRateLimitFormStyle } from './TokenRateLimitForm.css';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { PSMenuItem, Tooltip } from '../../../../ui-kit';

const TokenRateLimitForm: React.FC<IProtectionFormProps<TTokenRateLimitProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;
    const [globalTooltipOpen, setGlobalTooltipOpen] = useState(false);
    const [identityTooltipOpen, setIdentityTooltipOpen] = useState(false);

    return (
        <div css={TokenRateLimitFormStyle.self}>
            <Controller
                name={`${formName}.global_limit_per_minute`}
                defaultValue={protection.global_limit_per_minute}
                control={control}
                rules={{
                    min: 0,
                    max: 32678,
                }}
                render={({ field }) => (
                    <div>
                        <Tooltip
                            title='The value will be rounded down to the closest multiple of 8'
                            placement='top-start'
                            open={globalTooltipOpen}
                        >
                            <TextField
                                {...field}
                                size='small'
                                type="number"
                                label="Global Limit Per Minute"
                                variant="outlined"
                                fullWidth
                                InputProps={{ inputProps: { min: 0, max: 32678, step: 8 } }}
                                onFocus={() => setGlobalTooltipOpen(true)}
                                onBlur={event => {
                                    setGlobalTooltipOpen(false);
                                    field.onChange(tokenLimitToValidNumber(event.target.value));
                                }}
                            />
                        </Tooltip>
                    </div>
                )}
            />

            <Controller
                name={`${formName}.identity_limit_per_minute`}
                defaultValue={protection.identity_limit_per_minute}
                control={control}
                rules={{
                    min: 0,
                    max: 32678,
                }}
                render={({ field }) => (
                    <div>
                        <Tooltip
                            title='The value will be rounded down to the closest multiple of 8'
                            placement='top-start'
                            open={identityTooltipOpen}
                        >
                            <TextField
                                {...field}
                                size='small'
                                type="number"
                                label="Identity Limit Per Minute"
                                variant="outlined"
                                fullWidth
                                InputProps={{ inputProps: { min: 0, max: 32678, step: 8 } }}
                                onFocus={() => setIdentityTooltipOpen(true)}
                                onBlur={event => {
                                    setIdentityTooltipOpen(false);
                                    field.onChange(tokenLimitToValidNumber(event.target.value));
                                }}
                            />
                        </Tooltip>
                    </div>
                )}
            />

            {isAdvancedMode && <Controller
                name={`${formName}.encoding_name`}
                control={control}
                defaultValue={protection.encoding_name}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Encoding Name</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Encoding Name"
                        >
                            {Object.entries(TOKEN_LIMITATION_ENCODING_NAMES).map(([key, value]) => (
                                <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />}
        </div>
    )
}

export default TokenRateLimitForm;