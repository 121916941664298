/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSBoxStyle = {
  self: css`
    background: var(--color-white);
    border: 1px solid var(--color-black-30);

    &.box-shadow {
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    }
  `,
};
