/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { SortHeaderStyle } from './SortHeader.css';
import Text from '../../../Text/Text';
import Icon from '../../../Icon/Icon';

type IProps = {
    headerName: string;
    sortDirection?: 'asc' | 'desc';
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const SortIcon = {
    asc: 'PSArrowUpIcon',
    desc: 'PSArrowDownIcon',
    '': ''
} as const;

const SortHeader: React.FC<IProps> = (props) => {
    const { headerName, sortDirection = '', onClick } = props;

    const [sortIcon, setSortIcon] = React.useState<keyof typeof SortIcon>(sortDirection);

    const isSortActive = Boolean(sortDirection);

    const onMouseEnter = () => {
        if (isSortActive) return;
        setSortIcon('asc');
    }

    const onMouseLeave = () => {
        if (isSortActive) return;
        setSortIcon('');
    }

    useEffect(() => {
        setSortIcon(sortDirection);
    }, [sortDirection])

    return (
        <div css={SortHeaderStyle.self} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Text variant='smallBold' color={isSortActive ? 'black-70' : 'black-40'}>{headerName}</Text>
            <Icon iconSize={15} color={isSortActive ? 'black-70' : 'black-40'} iconName={SortIcon[sortIcon]} />
        </div>
    )
}

export default SortHeader;