/** @jsxImportSource @emotion/react */
import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { ProtectionChipStyle } from './ProtectionChip.css';
import { capitalizeFirstLetterAndLowerCaseRest } from '../../../../utils';
import { protectionKeysColorMap } from '../../../../routes-new/Manage';
import Chip from '../../../Chip/Chip';
import Circle from '../../../Circle/Circle';
import Text from '../../../Text/Text';

type IProps = {
    violationCount?: number;
    size?: 'small' | 'medium';
    showColorIndicator?: boolean;
} & Partial<CustomCellRendererProps>

const ProtectionChip: React.FC<IProps> = (props) => {
    const { value, violationCount, size = 'small', showColorIndicator = true } = props;
    const backgroundColor = protectionKeysColorMap[value as keyof typeof protectionKeysColorMap];
    const label = `${capitalizeFirstLetterAndLowerCaseRest(value)}${violationCount ? ` (${violationCount})` : ''}`;

    const circleSize = {
        small: 8,
        medium: 12,
    }[size];

    return (
        <Chip
            size="small"
            icon={showColorIndicator ? (
                <Circle
                    size={circleSize}
                    circleCss={ProtectionChipStyle.circle(backgroundColor)}
                />
            ) : undefined}
            label={
                <Text
                    ellipsis
                    textCss={ProtectionChipStyle.text}
                    variant='small'
                >{label}</Text>
            }
            variant="outlined"
            css={ProtectionChipStyle.self}
        />
    );
};

export default ProtectionChip;