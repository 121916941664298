/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { PSIconWithPopperStyle } from './PSIconWithPopper.css';
import {
    Text,
    Icon,
    PSPopper,
} from "../index";
import { PSIconNames } from "../Icon/Icon";

type TDataPoint = {
    title: string;
    value: string;
}

type IProps = {
    popperData: TDataPoint[];
    iconName: PSIconNames;
}

const PSIconWithPopper: FC<IProps> = (props) => {
    const { iconName, popperData } = props;

    const IconOnly = (<Icon iconName={iconName} />)

    if (!popperData?.length) return IconOnly;

    const Tooltip = (
        <div css={PSIconWithPopperStyle.tooltipContainer}>
            {
                popperData.map(({title, value}) => (
                    <div css={PSIconWithPopperStyle.tooltipSection} key={`${title}-${value}`}>
                        <Text variant='monoSmall'>{`${title}:`}</Text>
                        <Text variant='smallBold'>{value}</Text>
                    </div>
                ))
            }
        </div>
    );

    return (
        <div css={PSIconWithPopperStyle.self}>
            <PSPopper
                tooltip={Tooltip}
            >
                {IconOnly}
            </PSPopper>
        </div>
    )
}

export default PSIconWithPopper;