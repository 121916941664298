/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const DevelopersDeploymentsStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
        flex: 1;
    `,
    title: css`
        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    apiKey: css`
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;

        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    sectionContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
        align-items: start;
    `,
    sectionSelectContainer: css`
        gap: 10px;
        flex-wrap: wrap;
    `,
    toggleButton: css`
        border-radius: 10px !important;
        padding: 15px;
        padding-bottom: 10px;
        width: 150px;
        color: var(--color-purple-60) !important;
        
        &:not(.Mui-selected) {
            border: 1px solid var(--color-black-30) !important;
            color: var(--color-black-75) !important;
        }
    `,
    iconContainer: css`
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    `,
    codeViewerContainer: css`
        position: relative;
    `,
    copyButton: css`
        position: absolute;
        right: 10px;
        top: 10px;
    `,
}