/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ColorThemeKeys } from "../../styles";

export const PSRadioCardStyle = {
  self: css`
    border-radius: 20px;
    background: var(--color-white);
    border: 1px solid var(--color-black-30);
    padding: 20px;
    height: 200px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    user-select: none;

    input:disabled ~ & {
      background: var(--color-transparent);
      border: 1px solid var(--color-black-40);
      color: var(--color-black-40) !important;
    }

    &:not(input:disabled ~ &) {
      input:hover ~ & {
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      }

      input:active ~ & {
        background: var(--color-purple-5);
      }

      input:focus-visible ~ & {
        outline: 1px solid var(--color-purple-60);
        outline-offset: 5px;
      }
    }
  `,
  selfChecked: (selectedBorderColor: ColorThemeKeys) => css`
    border: 2px solid var(--color-${selectedBorderColor});
    color: var(--color-black) !important;
    box-shadow: 0px 4px 10px 0px rgba(97, 54, 253, 0.1);

    & .icon {
      fill: var(--color-purple-60);
    }
  `,
  radio: css`
    padding: 0px;
  `,
};
