/** @jsxImportSource @emotion/react */
import MuiChip, { ChipProps } from '@mui/material/Chip';
import styled from '@emotion/styled';
import { TextStyle } from '../Text/Text.css';

const Chip = styled(({ className, ...props }: ChipProps) => (
    <MuiChip {...props} className={className} />
))`
    & .MuiChip-label {
        color: var(--color-black-70);
        ${TextStyle.small};
    }
    
    &.MuiChip-filledDefault {
        background-color: var(--color-white);

        & .MuiChip-label {
            color: var(--color-black);
        }
    }

    &.MuiChip-outlined {
        border-color: var(--color-black-30);
    }
`;

export default Chip;