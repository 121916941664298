/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { TooltipProps } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { LogStatusStyle } from './LogStatus.css';
import Icon, {IconNames} from '../../../Icon/Icon';
import Tooltip from '../../../Tooltip/Tooltip';
import Text from '../../../Text/Text';

export const logActionNameToIconNameMap = {
    Blocked: 'PSBlockIcon',
    Block: 'PSBlockIcon',

    Modified: 'PSEditSquareIcon',
    Modify: 'PSEditSquareIcon',

    Logged: 'PSLogIcon',
    Log: 'PSLogIcon',

    Bypassed: 'PSBypassIcon',
    Bypass: 'PSBypassIcon',
} satisfies Record<string, IconNames>;

export type TLogActionName = keyof typeof logActionNameToIconNameMap;

type IProps = {
    iconOnly?: boolean;
} & Partial<CustomCellRendererProps>;

const LogStatus: React.FC<IProps> = (props) => {
    const { value, iconOnly = true } = props;

    const tooltipProps: Partial<TooltipProps> = useMemo(() => ({
        placement: 'right',
        disableInteractive: true,
        disableFocusListener: !iconOnly,
        disableHoverListener: !iconOnly,
        disableTouchListener: !iconOnly
    }), [iconOnly]);

    switch (value) {
        case 'Modify': {
            return <Tooltip {...tooltipProps} title="Modify">
                <div css={LogStatusStyle.self}>
                    <Icon iconName={logActionNameToIconNameMap[value as TLogActionName]} color='orange-50' iconSize={20} />
                    {!iconOnly && <Text>Modify</Text>}
                </div>
            </Tooltip>
        }
        case 'Block': {
            return <Tooltip {...tooltipProps} title="Block">
                <div css={LogStatusStyle.self}>
                    <Icon iconName={logActionNameToIconNameMap[value as TLogActionName]} color='red-50' iconSize={20} />
                    {!iconOnly && <Text>Block</Text>}
                </div>
            </Tooltip>
        }
        case 'Log': {
            return <Tooltip {...tooltipProps} title="Log">
                <div css={LogStatusStyle.self}>
                    <Icon iconName={logActionNameToIconNameMap[value as TLogActionName]} color='black-70' iconSize={20} />
                    {!iconOnly && <Text>Log</Text>}
                </div>
            </Tooltip>
        }
        case 'Bypass': {
            return <Tooltip {...tooltipProps} title="Bypass">
                <div css={LogStatusStyle.self}>
                    <Icon iconName={logActionNameToIconNameMap[value as TLogActionName]} color='orange-50' iconSize={20} />
                    {!iconOnly && <Text>Bypass</Text>}
                </div>
            </Tooltip>
        }
        default:
            return null
    }
}

export default LogStatus;