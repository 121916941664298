/** @jsxImportSource @emotion/react */
import { PSButton, PSDialog, Text } from '../ui-kit';
import { useNavigationBlocker } from '../hooks/useNavigationBlocker';
import { css } from '@emotion/react';
import { useState } from 'react';

type IProps = {
    when: boolean;
    onSave: () => Promise<void>;
}

export const PSNavigationBlocker: React.FC<IProps> = ({ when, onSave }) => {
    const { isBlocked, saveAndProceed, discardAndProceed, cancel, isLoading } = useNavigationBlocker(when, onSave);

    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const handleSaveAndProceed = async () => {
        setIsSaveLoading(true);
        try {
            await saveAndProceed();
        } catch (error) {
            console.error(error);
        }
        setIsSaveLoading(false);
    }

    return (
        <PSDialog
            open={isBlocked}
            onClose={cancel}
            title="Unsaved Changes"
            customActions={
                <div css={css`display: flex; gap: 15px; flex: 1;`}>
                    <PSButton css={css`margin-right: auto;`} disabled={isSaveLoading} variant='flat' onClick={cancel}>Cancel</PSButton>
                    <PSButton variant='outlined' disabled={isSaveLoading} onClick={discardAndProceed}>Discard Changes</PSButton>
                    <PSButton variant='filled' onClick={handleSaveAndProceed} isLoading={isSaveLoading}>Save Changes</PSButton>
                </div>
            }
        >
            <div css={css`display: flex; flex-direction: column; gap: 10px; margin-top: 20px; margin-bottom: 20px;`}>
                <Text>You have unsaved changes in your policy. If you leave this page, your changes will be lost.</Text>
                <Text>What would you like to do?</Text>
            </div>
        </PSDialog>
    );
}; 

export default PSNavigationBlocker;