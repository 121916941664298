/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Radio, RadioGroup } from '@mui/material';
import { PSDialog, PSFormControlLabel, Text, Icon, Tooltip } from '../../../ui-kit';
import { useGraphQL, useGraphQLMutation } from '../../../hooks';
import { graphql } from '../../../gql';
import { AgentSettingsDialogStyle } from './AgentSettingsDialog.css';
import { toast } from 'react-toastify';

const queryLoggingFrequencyInSecondsQuery = graphql(`
    query LoggingFrequencyInSecondsQuery {
        configuration {
            id
            loggingFrequencyInSeconds
            textRedactionMode
            protectApiQuota {
              remaining
            }
        }
    }
`);

const mutationUpdateLoggingFrequencyInSeconds = graphql(`
    mutation UpdateLoggingFrequencyInSeconds($id: ID!, $textRedactionMode: String!, $remainingProtectApiQuota: Int!, $loggingFrequencyInSeconds: Int!) {
        updateConfiguration(input: { id: $id, textRedactionMode: $textRedactionMode, remainingProtectApiQuota: $remainingProtectApiQuota, loggingFrequencyInSeconds: $loggingFrequencyInSeconds }) {
            id
        }
    }
`);

type IProps = {
    open: boolean;
    onClose: () => void;
};

const Frequencies = [
    { value: 'all', label: 'Log all violations', frequency: 0 },
    { value: 'hour', label: '1 hour', frequency: 60 * 60 },
    { value: 'day', label: '1 day', frequency: 60 * 60 * 24 },
    { value: 'week', label: '1 week', frequency: 60 * 60 * 24 * 7 },
    { value: 'twoWeeks', label: '2 weeks', frequency: 60 * 60 * 24 * 7 * 2 }
]

const AgentSettingsDialog: React.FC<IProps> = (props) => {
    const { open, onClose } = props;

    const [loggingFrequencyInSeconds, setLoggingFrequencyInSeconds] = useState<number>(0);

    const loggingFrequencyInSecondsQuery = useGraphQL({
        document: queryLoggingFrequencyInSecondsQuery,
        onSuccess: (data) => {
            setLoggingFrequencyInSeconds(data.configuration.loggingFrequencyInSeconds);
        }
    });

    const updateLoggingFrequencyInSecondsMutation = useGraphQLMutation({
        document: mutationUpdateLoggingFrequencyInSeconds,
        onSuccess: () => {
            loggingFrequencyInSecondsQuery.refetch();
            toast.success('Violations notification frequency updated to - ' + Frequencies.find(f => f.frequency === loggingFrequencyInSeconds)?.label);
        }
    });

    const onFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoggingFrequencyInSeconds(parseInt(e.target.value));
    }

    const handleSave = async () => {
        await updateLoggingFrequencyInSecondsMutation.mutateAsync({
            id: loggingFrequencyInSecondsQuery.data?.configuration?.id!,
            textRedactionMode: loggingFrequencyInSecondsQuery.data?.configuration?.textRedactionMode!,
            remainingProtectApiQuota: loggingFrequencyInSecondsQuery.data?.configuration?.protectApiQuota?.remaining!,
            loggingFrequencyInSeconds: loggingFrequencyInSeconds,
        });
    };

    if (loggingFrequencyInSecondsQuery.isLoading) return null;

    return (
        <PSDialog
            open={open}
            onClose={onClose}
            action={handleSave}
            title='Agent settings'
            actionButtonText='Save'
            preventEscapeAndBackdropClose
            isActionDisabled={loggingFrequencyInSeconds === loggingFrequencyInSecondsQuery.data?.configuration?.loggingFrequencyInSeconds}
        >
            <div css={AgentSettingsDialogStyle.container}>
                <div css={AgentSettingsDialogStyle.headerContainer}>
                    <Text variant='bold'>Violations notification frequency</Text>
                    <Tooltip
                        arrow
                        placement='right'
                        maxWidth={375}
                        title='Set the frequency of alerts for violations found in code. In other words, it determines how often the Prompt Security Platform will alert you about the same violation, such as an exposed cloud token, for the same user. Applies only when set to log violations only.'
                    >
                        <div css={AgentSettingsDialogStyle.iconWrapper}><Icon iconName='PSHelpIcon' /></div>
                    </Tooltip>
                </div>
                <RadioGroup
                    value={loggingFrequencyInSeconds}
                    onChange={onFrequencyChange}
                >
                    {Frequencies.map((frequency) => (
                        <PSFormControlLabel key={frequency.value} value={frequency.frequency} label={frequency.label} control={<Radio disableRipple size='small' css={AgentSettingsDialogStyle.radio} />} />
                    ))}
                </RadioGroup>
            </div>
        </PSDialog>
    )
}

export default AgentSettingsDialog;