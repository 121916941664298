/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const AgentSettingsDialogStyle = {
    container: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    `,
    headerContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    iconWrapper: css`
        display: flex;
    `,
    radio: css`
        padding: 7px 10px;
    `
}