/** @jsxImportSource @emotion/react */
import {FC, ReactNode, useRef, useState} from "react";
import Popper, {PopperProps} from '@mui/material/Popper';
import Box from '@mui/material/Box';
import {PSPopperStyles} from './PSPopper.css';
import {SerializedStyles} from "@emotion/react";

type IProps = {
    children: ReactNode;
    tooltip: ReactNode;
    tooltipContainerCss?: SerializedStyles;
    childrenContainerCss?: SerializedStyles;
} & Omit<PopperProps, "open">

const PSPopper: FC<IProps> = (props) => {
    const {children, tooltip, tooltipContainerCss, childrenContainerCss, ...RestPopperProps} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [arrowRef, setArrowRef] = useState();

    const elementRef = useRef<null | HTMLDivElement>(null);

    return (
        <div onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} css={PSPopperStyles.self}>
            <div ref={elementRef} css={childrenContainerCss}>
                {children}
            </div>
            <Popper
                placement='top'
                anchorEl={elementRef.current}
                {...RestPopperProps}
                open={isOpen}
                sx={{
                    zIndex: 20,
                    '&[data-popper-placement*="bottom"] .arrow': {
                        top: 15,
                        left: 0,
                        "&::before": {
                            transformOrigin: "0 100%"
                        }
                    },
                    '&[data-popper-placement*="top"] .arrow': {
                        bottom: 15,
                        left: 0,
                        "&::before": {
                            transformOrigin: "100% 0"
                        }
                    },
                    '&[data-popper-placement*="right"] .arrow': {
                        left: 15,
                        height: "12px",
                        width: "12px",
                        "&::before": {
                            transformOrigin: "100% 100%"
                        }
                    },
                    '&[data-popper-placement*="left"] .arrow': {
                        right: 15,
                        height: "12px",
                        width: "12px",
                        "&::before": {
                            transformOrigin: "0 0"
                        }
                    }
                }}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <Box
                    component="span"
                    ref={setArrowRef}
                    className='arrow'
                    sx={{
                        position: "absolute",
                        width: "12px",
                        height: "12px",
                        boxSizing: "border-box",
                        color: '#040F2D',
                        "&::before": {
                            content: '""',
                            margin: "auto",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "currentColor",
                            transform: "rotate(45deg)"
                        }
                    }}
                />
                <div css={[PSPopperStyles.popper, tooltipContainerCss]}>
                    {tooltip}
                </div>
            </Popper>
        </div>
    )
}

export default PSPopper;