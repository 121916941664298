import { keyframes } from "@emotion/react";

export const loadingAnimation = keyframes`
    from {
        background: var(--color-black-40);
    }
    to {
        background: var(--color-black-30);
    }
`;

const defaultPulseOptions = {
  fromColor: "var(--color-green-40)",
  toColor: "rgba(63, 203, 149, 0)",
  maxSize: 5,
};

export const pulse = (
  options: {
    fromColor?: string;
    toColor?: string;
    maxSize?: number;
  } = defaultPulseOptions
) => {
  options = { ...defaultPulseOptions, ...options };

  return keyframes`
    0% {
        box-shadow: 0 0 0 0px ${options.fromColor};
    }
    100% {
        box-shadow: 0 0 0 ${options.maxSize}px ${options.toColor};
    }`
};
