/** @jsxImportSource @emotion/react */
import {FC, useRef} from 'react';
import { GenAIApplicationIconsStyle } from './GenAIApplicationIcons.css';
import { GenAiApplication as TGenAiApplication } from "../../../../gql/generated/graphql";
import { GenAiApplication, Text, PSPopper } from '../../../index';
import { usePlusN } from "../../../../hooks";

type IProps = {
    apps: TGenAiApplication[];
    keyString: string;
    iconSize?: number;
    gap?: number;
}

const GenAIApplicationIcons: FC<IProps> = (props) => {
    const { apps, keyString, iconSize = 24, gap = 10 } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        isLimitCalculated,
        entitiesToDisplay: appsToDisplay,
        entitiesToHide: appsToHide,
    } = usePlusN({isSetWidth: true, entities: apps, containerRef, constantSize: iconSize, additionToEntityWidth: gap})

    if (!apps?.length) return null;

    return (
        <div css={GenAIApplicationIconsStyle.self(gap)} ref={containerRef}>
            {appsToDisplay && isLimitCalculated && appsToDisplay?.map((app) =>
                (<GenAiApplication value={app} iconSize={iconSize} isIconOnly key={`${keyString}-${app?.name}`}/>))}
            {appsToHide?.length > 0 &&
                (<PSPopper
                    key={`${keyString}-+N`}
                    tooltip={<Text>{appsToHide?.map(app => app.name).join(', ')}</Text>}
                >
                    <Text css={GenAIApplicationIconsStyle.plusN}>{`+${appsToHide.length}`}</Text>
                </PSPopper>)}
        </div>
    )
}

export default GenAIApplicationIcons;