/** @jsxImportSource @emotion/react */
import React, { useMemo, forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/base';
import { RoundedFlatButtonStyle } from './RoundedFlatButton.css';
import { FilledButtonStyle } from './FilledButton.css';
import { SquareButtonStyle } from './SquareButton.css';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

export type TFilledButtonVariantType = 'info' | 'critical';
export type TButtonSize = 'small' | 'medium';
export type TButtonVariant = 'outlined' | 'filled' | 'flat' | 'square';

type BaseProps = {
    variant: TButtonVariant;
    children?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    placement?: 'start' | 'end';
    iconName?: IconNames;
    isActive?: boolean;
    size?: TButtonSize;
} & ButtonProps;

type FlatVariantProps = {
    variant: 'filled';
    variantType: TFilledButtonVariantType
};

type ConditionalProps<T extends BaseProps['variant']> = T extends 'filled' ? FlatVariantProps : {};

type IProps<T extends BaseProps['variant'] = BaseProps['variant']> = BaseProps & ConditionalProps<T>;

const PSButton = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
    const {
        children,
        isLoading,
        iconName,
        placement,
        variant,
        isActive,
        className,
        size = 'medium',
        ...buttonProperties
    } = props;

    const ButtonStyle = useMemo(() => {
        switch (variant) {
            case 'flat':
                return RoundedFlatButtonStyle;
            case 'outlined':
            case 'filled': {
                const { variantType = 'info' } = props as FlatVariantProps;
                return FilledButtonStyle(variantType, size, variant);
            }
            case 'square':
                return SquareButtonStyle;
            default:
                throw new Error('Invalid variant');
        }
    }, [variant, (props as any)?.variantType, size]);

    return (
        <Button
            ref={ref}
            css={ButtonStyle.self}
            {...buttonProperties}
            disabled={isLoading || buttonProperties.disabled}
            className={clsx('ps-button', { 'active': isActive }, className)}
        >
            {isLoading && <>
                <CircularProgress color="inherit" size={20} />
            </>}
            {!isLoading && (
                <>
                    {iconName && (!placement || placement === 'start') && <Icon className="icon-start" iconName={iconName} />}
                    {children && <Text ellipsis variant='text' dataText={typeof children === 'string' ? children : undefined}>{children}</Text>}
                    {iconName && placement === 'end' && <Icon className="icon-end" iconName={iconName} />}
                </>
            )}
        </Button>
    );
});

export default PSButton;