import {useEffect, useMemo, useState, MutableRefObject} from "react";

type UsePlusNReturnTypeDefault = {
    isLimitCalculated: boolean;
    entitiesToDisplay: string[];
    entitiesToHide: string[];
};

type UsePlusNReturnTypeSetWidth<T> = {
    isLimitCalculated: boolean;
    entitiesToDisplay: T[];
    entitiesToHide: T[];
};

type TOptionsDefault = {
    entities: string[];
    containerRef: MutableRefObject<HTMLDivElement | null>;

    additionToEntityWidth?: number;

    isSetWidth?: false;
    constantSize?: never;
};

type TOptionsSetWidth<T> = {
    entities: T[];
    containerRef: MutableRefObject<HTMLDivElement | null>;

    isSetWidth: true;
    constantSize: number;

    additionToEntityWidth?: number;
};

type TOptions<T> = TOptionsDefault | TOptionsSetWidth<T>;

export function usePlusN(options: TOptionsDefault): UsePlusNReturnTypeDefault;

export function usePlusN<T>(options: TOptionsSetWidth<T>): UsePlusNReturnTypeSetWidth<T>;

export function usePlusN<T>(options: TOptions<T>): UsePlusNReturnTypeDefault | UsePlusNReturnTypeSetWidth<T> {
    const {
        entities,
        containerRef,
        additionToEntityWidth = 25,
        isSetWidth,
        constantSize,
    } = options;

    const [limit, setLimit] = useState<number>(0);
    const [isLimitCalculated, setIsLimitCalculated] = useState<boolean>(false);

    useEffect(() => {
        const updateLimitBasedOnWidth = () => {

            if (!entities?.length) return;
            if (entities.length === 1) {
                setLimit(1);
                setIsLimitCalculated(true);
                return;
            }

            if (isSetWidth && containerRef.current && entities.length > 0) {
                const maxWidth = containerRef.current.offsetWidth - constantSize;

                const maxAppsToDisplay = Math.floor(maxWidth / (constantSize + additionToEntityWidth))

                setLimit(maxAppsToDisplay);
                setIsLimitCalculated(true);
                return
            }

            if (containerRef.current && entities.length > 0) {
                const maxWidth = containerRef.current.offsetWidth;
                let totalWidth = 0;
                let newLimit = 0;

                const tempEntity = document.createElement("div");
                document.body.appendChild(tempEntity);

                for (let i = 0; i < entities.length; i++) {
                    tempEntity.innerText = (entities as string[])[i];
                    tempEntity.style.display = "inline-block";

                    const entityWidth = tempEntity.offsetWidth + additionToEntityWidth;
                    if (totalWidth + entityWidth < maxWidth) {
                        totalWidth += entityWidth;
                        newLimit++;
                    } else {
                        break;
                    }
                }

                document.body.removeChild(tempEntity);

                setLimit(newLimit);
                setIsLimitCalculated(true);
            }
        };

        setTimeout(() => {
            updateLimitBasedOnWidth();
        }, 0)
    }, [limit, entities, constantSize, additionToEntityWidth, isSetWidth, containerRef]);

    const entitiesToDisplay = useMemo(() => {
        return entities?.slice(0, limit);
    }, [limit, entities]);

    const entitiesToHide = useMemo(() => {
        return entities?.slice(limit);
    }, [limit, entities]);

    const returnObject = {
        isLimitCalculated,
        entitiesToDisplay,
        entitiesToHide,
    }

    if (options?.isSetWidth) return returnObject as UsePlusNReturnTypeSetWidth<T>;
    return returnObject as UsePlusNReturnTypeDefault;
}