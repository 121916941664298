/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PlusNStyle = {
    self: ( maxWidth: number, minWidth?: number) => css`
        ${minWidth ? css`min-width: ${minWidth}px;` : ''}
        ${maxWidth ? css`max-width: ${maxWidth}px;` : ''}
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    `,
};