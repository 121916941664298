/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Skeleton } from '@mui/material';
import { PSPieChartStyle } from './PSPieChart.css';
import { defaultTooltipFormatter, processData } from './PSPieChart.utils';

export type IProps = {
    height: number;
    isLoading?: boolean;
    data?: echarts.EChartOption.SeriesSunburst.DataObject[];
    minPercentage?: number;
    shouldSort?: boolean;
    maximumItemsCount?: number;
    series?: Omit<echarts.EChartOption.SeriesPie, 'data'>;
    onClickLabelOrPieSlice?: (name: string, data: any) => void;
    tooltipFormatter?: (params: echarts.EChartOption.Tooltip.Format) => string;
    children?: React.ReactNode;
    tooltip?: Omit<echarts.EChartOption.Tooltip, 'formatter'>;
}

const PSPieChart: React.FC<IProps> = (props) => {
    const {
        height,
        isLoading = false,
        data = [],
        children,
        minPercentage = 0,
        shouldSort = true,
        maximumItemsCount = Infinity,
        series = {},
        tooltip = {},
        onClickLabelOrPieSlice,
        tooltipFormatter = defaultTooltipFormatter,
    } = props;

    const ref = useRef(null);
    const chartRef = useRef<echarts.ECharts | null>(null);
    const [chartWidth, setChartWidth] = useState(0);

    useLayoutEffect(() => {
        if (!ref.current || chartRef.current) return;

        let resizeTimeout: NodeJS.Timeout;
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                chartRef.current?.resize();
                chartRef.current?.updateLabelLayout();
                setChartWidth(chartRef.current?.getWidth() || 0);
            }, 10);
        };

        const timer = setTimeout(() => {
            if (!ref.current) return;

            chartRef.current = echarts.init(ref.current);

            chartRef.current.on('click', (params: any) => {
                if (params.componentType === 'series' && params.componentSubType === 'pie') {
                    if (params.name && onClickLabelOrPieSlice) {
                        onClickLabelOrPieSlice(params.name, params.data);
                    }
                }
            });

            chartRef.current.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: (params: any) => tooltipFormatter(params),
                    padding: 0,
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    extraCssText: 'z-index: 20; white-space: pre-wrap;',
                    show: !isLoading,
                    ...tooltip,
                },
                grid: {
                    containLabel: true,
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['90%', '83%'],
                        //@ts-ignore
                        padAngle: 2,
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 15,
                        },
                        label: {
                            show: !isLoading,
                            position: 'outside',
                            formatter: (params: any) => {
                                return `{name|${params.name.split(' ').join('\n')}} {value|(${params.value})}`;
                            },
                            align: 'center',
                            rich: {
                                name: {
                                    padding: [0, 0, 5, 0],
                                    align: 'center'
                                },
                                value: {
                                    align: 'center'
                                }
                            },
                            emphasis: {
                                show: true,
                                distance: 15
                            }
                        },
                        labelLine: {
                            show: false,
                            length2: 30,
                            smooth: true
                        },
                        hoverOffset: 0,
                        emphasis: {
                            //@ts-ignore
                            scale: false,
                            itemStyle: {
                                shadowBlur: 0,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0
                            }
                        },
                        data: data,
                        center: ['50%', '50%'],
                        zlevel: 0,
                        ...series,
                    }
                ]
            });
        }, 0);

        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            clearTimeout(timer);
            clearTimeout(resizeTimeout);
            resizeObserver.disconnect();
            window.removeEventListener('resize', handleResize);
            chartRef.current?.dispose();
            chartRef.current = null;
        };
    }, []);

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            chartRef.current?.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => tooltipFormatter(params as echarts.EChartOption.Tooltip.Format),
                    padding: 0,
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    extraCssText: 'z-index: 20;',
                    show: !isLoading,
                    ...tooltip,
                },
                series: [{
                    name: 'Access From',
                    type: 'pie',
                    radius: ['90%', '83%'],
                    //@ts-ignore
                    padAngle: (data.length === 1 || isLoading) ? 0 : 2,
                    avoidLabelOverlap: true,
                    itemStyle: {
                        borderRadius: 15,
                    },
                    label: {
                        show: !isLoading,
                        position: 'outside',
                        formatter: (params: any) => {
                            return `{name|${params.name.split(' ').join('\n')}} {value|(${params.value})}`;
                        },
                        align: 'center',
                        rich: {
                            name: {
                                padding: [0, 0, 5, 0],
                                align: 'center'
                            },
                            value: {
                                align: 'center'
                            }
                        }
                    },
                    labelLine: {
                        show: false,
                        length2: 30,
                    },
                    hoverOffset: 0,
                    data: isLoading ? [] : (data.length > 0 ? processData(data, minPercentage, shouldSort, maximumItemsCount) : []),
                    center: ['50%', '50%'],
                    zlevel: 0,
                    ...series,
                }]
            }, { notMerge: false, lazyUpdate: true })
        }, 0)

        return () => {
            clearTimeout(timer);
        }
    }, [isLoading, data, minPercentage, shouldSort, maximumItemsCount, series, tooltipFormatter])

    return (
        <div css={PSPieChartStyle.container(height)}>
            <div css={PSPieChartStyle.chartContainer(height)} ref={ref}></div>
            <div css={PSPieChartStyle.centerContent(height, chartWidth)}>
                {isLoading ? (
                    <div css={PSPieChartStyle.loadingSkeleton}>
                        <Skeleton variant='rectangular' animation='pulse' width='90px' height='20px' sx={{ borderRadius: '4px' }} />
                        <Skeleton variant='rectangular' animation='pulse' width='90px' height='45px' sx={{ borderRadius: '4px' }} />
                        <Skeleton variant='rectangular' animation='pulse' width='90px' height='15px' sx={{ borderRadius: '4px' }} />
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>
    );
}

export default PSPieChart;