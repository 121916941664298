/** @jsxImportSource @emotion/react */
import { useState, useRef, useLayoutEffect } from 'react';
import styled from "@emotion/styled";
import { Tabs, TabsProps } from "@mui/material";
import PSMenu from '../PSMenu/PSMenu';
import PSTab from '../PSTab/PSTab';
import { css } from '@emotion/react';
import { debounce } from 'lodash';
import React from 'react';

interface PSTabsProps extends TabsProps {
    separator?: boolean;
    removeBorderBottom?: boolean;
    withOverflow?: boolean;
    onOverflowChange?: (overflowTabs: React.ReactNode[]) => void;
}

const TabsWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledTabs = styled(Tabs) <PSTabsProps>`
    border-bottom: ${props => props.removeBorderBottom ? 'none' : '1px solid var(--color-black-30)'};
    padding: 0;
    min-height: 50px;
    .MuiTabs-indicator {
        display: none;
        background-color: var(--color-blue-20);
        height: 3px;
        background: var(--color-black);
    };

    .MuiTabs-flexContainer:has(.loading) {
        & + .MuiTabs-indicator {
            display: none;
        }
        gap: 10px;
    }
`;

export const PSTabs = ({
    separator,
    removeBorderBottom,
    withOverflow,
    children,
    onOverflowChange,
    ...props
}: PSTabsProps) => {
    const [visibleTabs, setVisibleTabs] = useState<React.ReactNode[]>(
        React.Children.toArray(children)
    );
    const [overflowTabs, setOverflowTabs] = useState<React.ReactNode[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const tabsRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!withOverflow) return;

        const timeoutId = setTimeout(() => {
            const calculateVisibleTabs = () => {
                const container = tabsRef.current;
                if (!container) return;

                const containerWidth = container.offsetWidth;
                const allTabs = React.Children.toArray(children);
                const moreButtonWidth = 100;
                const totalReservedSpace = moreButtonWidth;
                const availableWidth = containerWidth - totalReservedSpace;

                setVisibleTabs(allTabs);

                requestAnimationFrame(() => {
                    const tabElements = container.querySelectorAll('.MuiTab-root');
                    let currentWidth = 0;
                    let overflowStartIndex = -1;

                    for (let i = 0; i < tabElements.length; i++) {
                        const tabWidth = tabElements[i].getBoundingClientRect().width;
                        if (currentWidth + tabWidth > availableWidth) {
                            overflowStartIndex = i;
                            break;
                        }
                        currentWidth += tabWidth;
                    }

                    if (overflowStartIndex !== -1) {
                        const visible = allTabs.slice(0, overflowStartIndex);
                        const overflow = allTabs.slice(overflowStartIndex);

                        setVisibleTabs(visible);
                        setOverflowTabs(overflow);
                        onOverflowChange?.(overflow);
                    } else {
                        setVisibleTabs(allTabs);
                        setOverflowTabs([]);
                        setAnchorEl(null);
                        onOverflowChange?.([]);
                    }
                });
            };

            const debouncedCalculateVisibleTabs = debounce(calculateVisibleTabs, 150);

            calculateVisibleTabs();
            window.addEventListener('resize', debouncedCalculateVisibleTabs);

            return () => {
                window.removeEventListener('resize', debouncedCalculateVisibleTabs);
                debouncedCalculateVisibleTabs.cancel();
            };
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [children, withOverflow, onOverflowChange]);

    const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const className = `${props.className || ''} ${separator ? 'separator' : ''}`;

    return (
        <TabsWrapper ref={tabsRef}>
            <StyledTabs {...props} className={className}>
                {withOverflow ? (
                    [...visibleTabs, overflowTabs.length > 0 && (
                        <PSTab
                            key="more"
                            value="more"
                            label="More"
                            isSelected={overflowTabs.some(tab => (tab as React.ReactElement).props.value === props.value)}
                            iconName={Boolean(anchorEl) ? 'PSChevronUpIcon' : 'PSChevronDownIcon'}
                            onClick={handleMoreClick}
                            withoutIndicator
                        />
                    )].filter(Boolean)
                ) : (
                    React.Children.toArray(children)
                )}
            </StyledTabs>

            {withOverflow && (
                <PSMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {overflowTabs.map((tab, index) => (
                        <div css={css`
                            width: 100%;
                            & > * {
                                width: 100%;
                                height: 0;
                            }
                        `} key={index}>{
                                React.cloneElement(tab as React.ReactElement, {
                                    isSelected: props.value === (tab as React.ReactElement).props.value
                                })
                            }</div>
                    ))}
                </PSMenu>
            )}
        </TabsWrapper>
    );
};

export default PSTabs;