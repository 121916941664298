/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSComponentWithBadgeStyle = {
    self: css`
        display: flex;
        align-items: center;
        justify-content: start;
        height: 100%;
        position: relative;
        padding-right: 18px;
    `,
    badge: css`
        position: absolute;
        top: 15px;
        right: 0;
    `,
};