/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TPSIconButtonVariant, TPSIconButtonVariantType } from "./PSIconButton";

export const PSIconButtonStyle = (variant: TPSIconButtonVariant, variantType: TPSIconButtonVariantType) => {
  let baseRadius = '50%';

  switch (variant) {
    case 'square':
      baseRadius = '5px';
      break;
    case 'circle':
      baseRadius = '50%';
      break;
  }

  const colors = {
    primary: {
      iconFill: 'purple-80',
      hoverBackground: 'blue-5',
      activeBackground: 'blue-20',
      focusBorder: 'blue-20',
      loadingBackground: 'blue-5'
    },
    secondary: {
      iconFill: 'blue-80',
      hoverBackground: 'black-20',
      activeBackground: 'blue-20',
      focusBorder: 'blue-20',
      loadingBackground: 'black-20'
    }
  };

  const colorSet = colors[variantType];

  return {
    self: css`
    display: flex;
    cursor: pointer;
    padding: var(--spacing-xs);
    border-radius: ${baseRadius};
    border: 1px solid var(--color-transparent);
    background: none;
    outline: none;

    & .ps-icon-button-icon {
      fill: var(--color-${colorSet.iconFill});
    }

    &.loading {
      background: var(--color-${colorSet.loadingBackground});
    }

    &.disabled {
      & .ps-icon-button-icon {
        fill: var(--color-black-30);
      }
      cursor: default;
      pointer-events: none;
    }

    :not(.disabled) {
      &:hover {
        background: var(--color-${colorSet.hoverBackground});
      }

      &:focus-visible {
        border: 1px solid var(--color-${colorSet.focusBorder});
      }

      &:active,
      &.active {
        border-color: var(--color-transparent);
        background: var(--color-${colorSet.activeBackground});
      }}
    `,
  }
};