/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const GenAIApplicationIconsStyle = {
    self: (gap: number) => css`
        display: flex;
        align-items: center;
        gap: ${gap}px;
        width: 100%;
        max-width: 250px;
        height: 100%;
    `,
    plusN: css`
        padding-top: 2px;
    `,
}