/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSPaginationStyle = {
    self: css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border: 1px solid var(--color-black-20);
        border-top: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        overflow: hidden;
    `,

    paginationActions: css`
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
    `,

    paginationText: css`
        position: absolute;
        right: 100%;
        margin-right: 20px;
        white-space: nowrap;
    `
}
