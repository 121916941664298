/** @jsxImportSource @emotion/react */
import React from 'react';
import { ColorThemeKeys } from '../../styles';
import Icon, { IconNames } from '../Icon/Icon';
import { PSImageBoxStyle } from './PSImageBox.css';

type IProps = {
    imageUrl?: string;
    size?: number;
    borderColor?: ColorThemeKeys;
} | {
    iconName: IconNames;
    size?: number;
    borderColor?: ColorThemeKeys;
};

const PSImageBox: React.FC<IProps> = (props) => {
    const { size = 70, borderColor = 'black-30' } = props;
    const paddingSizePx = Math.floor(size * 0.14);


    if ('iconName' in props) {
        const { iconName, } = props;
        return (
            <div css={PSImageBoxStyle.selfIcon(paddingSizePx, size, borderColor)}>
                <Icon iconName={iconName} iconSize={size - paddingSizePx} color={borderColor} />
            </div>
        )
    }

    const { imageUrl } = props;
    return (
        <img
            css={PSImageBoxStyle.self(paddingSizePx, size, borderColor)}
            src={imageUrl || `https://via.placeholder.com/${size - paddingSizePx}`}
            onError={(e) => {
                e.currentTarget.src = `https://via.placeholder.com/${size - paddingSizePx}`;
            }}
            alt="box"
        />
    )
}

export default PSImageBox;