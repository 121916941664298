/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const OWASPTopTenStyle = {
    self: (gap: number) => css`
        width: 100%; 
        display: flex;
        gap: ${gap}px;
    `,
    plusNTooltip: css`
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5px;
        row-gap: 10px;
    `,
};