/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from 'react';
import { PSEmptyStateStyles } from './PSEmptyState.css'
import { Text, Icon } from '../../ui-kit';
import { PSIconNames } from "../Icon/Icon";
import { ColorThemeKeys } from "../../styles";
import { TModes, TSizes } from "./types";
import { TextStyle } from "../Text/Text.css";

export type TTextVariant = keyof typeof TextStyle;

type TVariants = 'noData' | 'error';

type TVariantConfigValues = {
    iconName: Record<TSizes, PSIconNames>;
    iconColor?: ColorThemeKeys;
}

const variantConfig: Record<TVariants, TVariantConfigValues> = {
    noData: {
        iconName: {
            large: 'PSErrorNoDataLargeIcon',
            medium: 'PSErrorNoDataMediumIcon',
            small: 'PSInfoIcon',
        },
        iconColor: 'purple-70',
    },
    error: {
        iconName: {
            large: 'PSErrorCriticalLargeIcon',
            medium: 'PSErrorCriticalMediumIcon',
            small: 'PSCriticalIcon',
        },
        iconColor: 'red-50',
    },
}

type TSizeMapValue = {
    iconWidth: number;
    iconHeight: number;
    titleVariant: TTextVariant;
    descriptionVariant: TTextVariant;
}

const sizesMap: Record<TSizes, TSizeMapValue> = {
    large: {
        iconWidth: 281,
        iconHeight: 310,
        titleVariant: 'header',
        descriptionVariant: 'header3',
    },
    medium: {
        iconWidth: 180,
        iconHeight: 120,
        titleVariant: 'header2',
        descriptionVariant: 'text',
    },
    small: {
        iconWidth: 20,
        iconHeight: 20,
        titleVariant: 'text',
        descriptionVariant: 'text',
    },
}

type BaseProps = {
    title: string | ReactNode;
    description?: string | ReactNode;
    mode?: TModes;
    size?: TSizes;
}

type StandardVariantProps = {
    variant: TVariants;
};

type CustomVariantProps = {
    variant: 'custom';
    iconName: PSIconNames;
    iconWidth: number;
    iconHeight: number;
    iconColor?: ColorThemeKeys;
};

type IProps = BaseProps & (StandardVariantProps | CustomVariantProps);

const PSEmptyState: FC<IProps> = (props) => {
    const {
        title,
        description,
        variant,
        mode = 'vertical',
        size = 'large',

        iconName,
        iconWidth,
        iconHeight,
        iconColor,
    } = props as BaseProps & CustomVariantProps;

    const isTitleString = typeof title === 'string';
    const isDescriptionString = typeof description === 'string';

    return (
        <div css={PSEmptyStateStyles.self(mode, size)}>
            <div>
                <Icon
                    iconName={iconName || variantConfig[variant as TVariants].iconName[size]}
                    iconSize='custom'
                    width={iconWidth || sizesMap[size].iconWidth}
                    height={iconHeight || sizesMap[size].iconHeight}
                    color={variant === 'custom' ? iconColor : variantConfig[variant as TVariants].iconColor}
                />
            </div>
            <div css={PSEmptyStateStyles.textContainer(mode, size)}>
                {isTitleString ? <Text variant={sizesMap[size].titleVariant}>{title}</Text> : title}

                {isDescriptionString ? <Text variant={sizesMap[size].descriptionVariant} color='black-70'>{description}</Text> : description}
            </div>
        </div>
    )
}

export default PSEmptyState;