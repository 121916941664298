/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const NoDataStyle = {
  self: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,
  iconContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: var(--color-black-20);
    width: fit-content;
    height: fit-content;
  `,
  noDataHeader: css`
    margin-top: 14px;
  `,
  noDataMessage: css`
    margin-top: 8px;
  `,
};
