/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const ProtectionChipsStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 450px;
    `,
};