import { css } from '@emotion/react';
import { TIConSize } from './Icon';

export const IconStyle = {
    self: css`
        transition: none;
    `,
    iconSize: (iconSize: TIConSize | number) => {
        if (typeof iconSize === 'number') {
            return css`
                width: ${iconSize}px;
                height: ${iconSize}px;
            `;
        }

        switch (iconSize) {
            case 'small':
                return css`
                    width: 16px;
                    height: 16px;
                `;
            case 'medium':
                return css`
                    width: 24px;
                    height: 24px;
                `;
            case 'large':
                return css`
                    width: 32px;
                    height: 32px;
                `;
        }
    },
    customIconSize: (width: number, height: number) => css`
        width: ${width}px;
        height: ${height}px;
    `
};