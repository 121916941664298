/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TextStyle } from "../Text/Text.css";

export const PSSelectStyle = {
  self: css`
    border-radius: 5px;
    height: 30px;

    & .MuiInputBase-input {
      ${TextStyle.small}
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-purple-60);
        border-width: 1px;
      }
      .MuiSvgIcon-root {
        fill: var(--color-black);
        transform: none;
      }
    }
  `,
  description: (parentWidth: number) => css`
    padding: 5px 14px 15px 40px;
    max-width: ${parentWidth - 20}px;
  `,
};
