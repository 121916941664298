import React, { createContext, useState } from 'react';
import {useGraphQL} from "../hooks";
import {PSLoadingScreen} from "../ui-kit";
import {graphql} from "../gql";

const queryAllConnectors = graphql(`
    query queryAllConnectors {
        applications {
            name
            applicationType
        }
    }
`);

type DashboardContextType = {
    isShowErrorForAllPage: boolean;
    setIsShowErrorForAllPage: (value: boolean) => void;

    violationsCount: number | undefined;
    setViolationsCount: (value: number) => void;

    promptsCount: number | undefined;
    setPromptsCount: (value: number) => void;

    installedExtensionsCount: number | undefined;
    setInstalledExtensionsCount: (value: number) => void;

    employeesConnectorName: string | undefined;
}

export const DashboardsContext = createContext<DashboardContextType | null>(null);

type IProps = {
    children: React.ReactNode;
};

export const DashboardsContextProvider: React.FC<IProps> = (props) => {
    const {children} = props;

    const [violationsCount, setViolationsCount] = useState<number>();
    const [promptsCount, setPromptsCount] = useState<number>();
    const [installedExtensionsCount, setInstalledExtensionsCount] = useState<number>();

    const [isShowErrorForAllPage, setIsShowErrorForAllPage] = useState(false);

    const { data, isLoading } = useGraphQL({
        document: queryAllConnectors,
    });

    const employeesConnectorName = data?.applications?.find((app) => app.applicationType === 'EXTENSION')?.name;

    const value = {
        violationsCount,
        setViolationsCount,
        promptsCount,
        setPromptsCount,
        installedExtensionsCount,
        setInstalledExtensionsCount,
        isShowErrorForAllPage,
        setIsShowErrorForAllPage,
        employeesConnectorName,
    };

    if (isLoading) return <PSLoadingScreen />

    return (
        <DashboardsContext.Provider value={value}>
            {children}
        </DashboardsContext.Provider>
    );
};

export const useDashboardsContext = () => {
    const context = React.useContext(DashboardsContext);
    if (!context) {
        throw new Error('useDashboardsContext must be used within a DashboardsContextProvider');
    }

    return context;
};