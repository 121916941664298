/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const ProtectionChipStyle = {
    self: css`
        /* width: fit-content; */
    `,
    circle: (backgroundColor: string) => css`
        margin-left: 7px;
        background-color: ${backgroundColor};
    `,
    text: css`
        color: var(--color-black-70);
    `
}