/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, TPromptLeakDetectorProtection } from '../Common';
import { PromptLeakDetectorFormStyle } from './PromptLeakDetectorForm.css';
import { Controller } from 'react-hook-form';
import { Text } from '../../../../ui-kit';
import { Slider } from '@mui/material';

const PromptLeakDetectorForm: React.FC<IProtectionFormProps<TPromptLeakDetectorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <div css={PromptLeakDetectorFormStyle.self}>
            <Controller
                name={`${formName}.threshold`}
                defaultValue={protection.threshold}
                control={control}
                rules={{
                    min: 0,
                    max: 1,
                }}
                render={({ field }) => (
                    <div>
                        <Text>Threshold</Text>
                        <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                            marks={[
                                { value: 0, label: '0' },
                                { value: 0.25, label: '0.25' },
                                { value: 0.5, label: '0.5' },
                                { value: 0.75, label: '0.75' },
                                { value: 1, label: '1' },
                            ]}
                        />
                    </div>
                )}
            />

            <Controller
                name={`${formName}.chunksize`}
                defaultValue={protection.chunksize}
                control={control}
                render={({ field }) => (
                    <div>
                        <Text>Chunk Size</Text>
                        <Slider {...field} min={10} max={200} step={5} valueLabelDisplay="auto"
                            marks={[
                                { value: 10, label: '10' },
                                { value: 50, label: '50' },
                                { value: 100, label: '100' },
                                { value: 150, label: '150' },
                                { value: 200, label: '200' },
                            ]}
                        />
                    </div>
                )}
            />
        </div>
    )
}

export default PromptLeakDetectorForm;