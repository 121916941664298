/** @jsxImportSource @emotion/react */
import React from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import { PSMenuItemStyle } from './PSMenuItem.css';
import PSCheckbox from '../PSCheckbox/PSCheckbox';

interface BasePSMenuItemProps extends MenuItemProps {
    selected?: boolean;
    type?: 'checkbox' | 'icon' | 'none' | 'custom';
}

interface CustomTypeProps {
    iconName: IconNames;
}

type PSMenuItemProps = (BasePSMenuItemProps & { type?: 'checkbox' | 'icon' | 'none' }) | (BasePSMenuItemProps & { type: 'custom' } & CustomTypeProps);

const PSMenuItem: React.FC<PSMenuItemProps> = (props) => {
    const { selected, children, type = 'icon', ...menuItemProps } = props;
    return (
        <MenuItem {...menuItemProps} css={PSMenuItemStyle.self}>
            <>
                {type === 'none' && <></>}
                {type === 'checkbox' && <PSCheckbox disableRipple size='small' checked={selected} />}
                {type === 'icon' && <Icon iconName={"PSCheckmarkIcon"} iconSize={20} color={selected ? 'black' : 'transparent'} />}
                {type === 'custom' && <Icon iconName={(props as any).iconName} iconSize={15} color='black' />}
            </>
            <Text tooltipDisableInteractive ellipsis textCss={PSMenuItemStyle.text} variant={selected ? 'bold' : 'text'}>{children}</Text>
        </MenuItem>
    );
};

export default PSMenuItem;