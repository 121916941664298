/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const AddVerifiedApplicationModalStyle = {
  self: css``,
  dialogContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  searchContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  `,
  applicationsContainer: (openCustomAppModal: boolean) => css`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 10px;
    overflow-y: auto;
    max-height: calc(100vh - ${openCustomAppModal ? 410 : 340}px);
  `,
  addCustomApplicationContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-black-30);
    padding-top: 20px;
  `,
};
