/** @jsxImportSource @emotion/react */
import React, { forwardRef, useEffect, useRef } from 'react';
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ColDef, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy, SizeColumnsToContentStrategy, SortChangedEvent, RowClickedEvent, SelectionChangedEvent } from 'ag-grid-community';

import { css } from "@emotion/react";
import { PSPagination, NoData, PSError, Text } from "../../index";
import { Skeleton } from "@mui/material";
import { Log, Pagination, SortByFields, SortDirection } from "../../../gql/generated/graphql";
import { createPlaceholderData } from "../../../utils";

export type GridPaginationModel = 'pagination' | 'infiniteScroll' | 'static';

// Base interface with common properties
interface BaseGridProps {
    colDefs: Array<ColDef>;
    selectedRowIdx?: number | null;
    isError?: boolean;
    isLoading?: boolean;
    data?: any[];
    selectedRows?: any[];
    rowHeight?: number;
    noDataMessage?: string;
    setRowIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    handleSelectionChanged?: (event: SelectionChangedEvent) => void;
}
interface PaginationGridProps extends BaseGridProps {
    paginationModel: 'pagination';
    pagination?: Pagination;
    currentPage: number;
    itemsPerPage: number;
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
    setItemsPerPage?: React.Dispatch<React.SetStateAction<number>>;
    setSortBy?: React.Dispatch<React.SetStateAction<SortByFields>>;
    setSortDirection?: React.Dispatch<React.SetStateAction<SortDirection>>;
}

interface InfiniteScrollGridProps extends BaseGridProps {
    paginationModel: 'infiniteScroll';
    onLoadMore: () => void;
    hasMore: boolean;
    setSortBy?: React.Dispatch<React.SetStateAction<SortByFields>>;
    setSortDirection?: React.Dispatch<React.SetStateAction<SortDirection>>;
}
interface StaticGridProps extends BaseGridProps {
    paginationModel: 'static';
    setSortBy?: React.Dispatch<React.SetStateAction<SortByFields>>;
    setSortDirection?: React.Dispatch<React.SetStateAction<SortDirection>>;
}

// Combined type
export type GridProps = PaginationGridProps | InfiniteScrollGridProps | StaticGridProps;

export const Grid = forwardRef<AgGridReact, GridProps>((props, ref) => {
    const {
        colDefs,
        selectedRowIdx,
        data,
        handleSelectionChanged,
        isError,
        setSortBy,
        setSortDirection,
        isLoading,
        setRowIndex,
        rowHeight = 50,
        noDataMessage = 'Try expanding your selected date range and removing any filters.',
        paginationModel = 'pagination',
    } = props;

    // Type guard functions
    const isPaginationGrid = (props: GridProps): props is PaginationGridProps => {
        return props.paginationModel === 'pagination';
    };

    const isInfiniteScrollGrid = (props: GridProps): props is InfiniteScrollGridProps => {
        return props.paginationModel === 'infiniteScroll';
    };

    const setCurrentPage = isPaginationGrid(props) ? props.setCurrentPage : undefined;
    const pagination = isPaginationGrid(props) ? props.pagination : undefined;

    const onLoadMore = isInfiniteScrollGrid(props) ? props.onLoadMore : undefined;
    const hasMore = isInfiniteScrollGrid(props) ? props.hasMore : false;

    const gridRef = useRef<AgGridReact>(null);
    const scrollPositionRef = useRef<number>(0);

    useEffect(() => {
        if (paginationModel === 'infiniteScroll' && gridRef.current?.api) {
            //@ts-ignore
            const gridBody = gridRef.current.api.gridBodyCtrl.eBodyViewport;
            if (gridBody) {
                gridBody.scrollTop = scrollPositionRef.current;
            }
        }
    }, [data, paginationModel]);

    const defaultColDef: ColDef = React.useMemo(() => {
        return {
            sortable: false,
            filter: false,
            resizable: false,
            rowDrag: false,
            suppressMovable: true,
            minWidth: 150,
            cellRenderer: (params: CustomCellRendererProps) => {
                if (params.data?.isError) return <PSError direction='row' message='row load failed' />
                return <Text ellipsis>{params.valueFormatted ?? params.value}</Text>
            },
            cellRendererSelector: (params) => {
                if (isInfiniteScrollGrid(props) && isLoading) {
                    const isLastRow = params.rowIndex === (data?.length || 0) - 1;
                    if (isLastRow) {
                        return {
                            component: () => <Skeleton variant='text' animation='pulse' height={20} width={'100%'} className='loading-skeleton-infinite-scroll' />
                        }
                    }
                }

                if (params.data?.id && typeof params.data.id === 'string' && params.data.id.startsWith('placeholder') || 
                    (isLoading && !isInfiniteScrollGrid(props))) {
                    return {
                        component: () => <Skeleton variant='text' animation='pulse' height={20} width={'100%'} className='loading-skeleton' />
                    }
                }
            },
            cellStyle: (params) => {
                if (params.rowIndex === selectedRowIdx) {
                    return { background: 'var(--color-black-20)' }
                } else {
                    return { background: 'transparent' }
                }
            }
        }
    }, [selectedRowIdx, isLoading, data?.length, isInfiniteScrollGrid])

    const autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
        type: 'fitGridWidth',
        columnLimits: [
            {
                colId: 'select',
                maxWidth: 65,
                minWidth: 65
            }
        ],
    }

    const placeholderData = createPlaceholderData(20)

    const handleSortChanged = (event: SortChangedEvent<Log>) => {
        const columnsChanged = event.columns;

        if (!columnsChanged) return;
        if (columnsChanged.length === 0) return;

        const columnToSort = columnsChanged[0];

        const sortDirection = columnToSort.getSort();
        const sortBy = columnToSort.getColDef().field as SortByFields;

        const SortDirectionMap = {
            'asc': SortDirection.Ascending,
            'desc': SortDirection.Descending
        }

        setSortBy?.(sortBy);
        setSortDirection?.(SortDirectionMap[sortDirection || 'desc']);
        if (setCurrentPage) setCurrentPage(1);
    }

    const handleRowClick = (event: RowClickedEvent) => {
        if (isLoading) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="actions"]')) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="select"]')) return;
        if (event.data?.isError) return;
        setRowIndex?.(event.rowIndex);
    }

    const handleScroll = (event: any) => {
        if (paginationModel !== 'infiniteScroll') return;

        const grid = event.api.gridBodyCtrl.eBodyViewport;
        const scrollPosition = grid.scrollTop;
        const scrollHeight = grid.scrollHeight;
        const clientHeight = grid.clientHeight;

        scrollPositionRef.current = scrollPosition;

        if (scrollHeight - scrollPosition - clientHeight < 100 && hasMore && !isLoading && onLoadMore) {
            onLoadMore();
        }
    };

    return (
        <div className="ag-theme-quartz" style={{ flexGrow: 1 }}>
            <AgGridReact
                ref={(element) => {
                    //@ts-ignore
                    gridRef.current = element;
                    if (typeof ref === 'function') {
                        ref(element);
                    } else if (ref) {
                        ref.current = element;
                    }
                }}
                rowHeight={rowHeight}
                css={css`--ag-wrapper-border-radius: ${isPaginationGrid(props) ? '15px 15px 0 0' : '15px'}; height: ${isPaginationGrid(props) ? 'calc(100% - 50px) !important' : '100%'};`}
                className="clickable-rows"
                rowSelection='multiple'
                autoSizeStrategy={autoSizeStrategy}
                onSortChanged={handleSortChanged}
                onRowClicked={handleRowClick}
                defaultColDef={defaultColDef}
                rowData={isError ? [] : (data || placeholderData)}
                columnDefs={colDefs}
                animateRows={false}
                tooltipTrigger={undefined}
                tooltipShowDelay={10000000000}
                onSelectionChanged={handleSelectionChanged}
                noRowsOverlayComponent={() => {
                    if (isError) {
                        return <PSError />
                    }
                    return <NoData message={noDataMessage} />
                }}
                suppressRowClickSelection
                suppressCellFocus
                onBodyScroll={handleScroll}
                pagination={paginationModel === 'pagination'}
                suppressPaginationPanel={true}
            />
            {isPaginationGrid(props) && (
                <PSPagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalItems={pagination?.totalCount ?? 0}
                    itemsPerPage={pagination?.itemsPerPage ?? 50}
                    onPageChange={setCurrentPage}
                    isLoading={isLoading}
                />
            )}
        </div>
    )
});