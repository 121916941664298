/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const MicrosoftSCCM: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to deploy and execute your PowerShell script on assigned devices using SCCM</Text>
            <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li>
                    <Text><b>Open the SCCM Console</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Launch the <b>Configuration Manager Console</b> on your system.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Go to Software Library</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>In the console, navigate to <b>"Software Library"</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Create a New Package</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Under <b>"Application Management"</b>, select <b>"Packages"</b>.</Text></li>
                        <li><Text>Right-click <b>"Packages"</b> and choose <b>"Create Package"</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Configure Package</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Enter the <b>name</b>, <b>version</b>, and <b>description</b> of the package.</Text></li>
                        <li><Text>Set the <b>source folder</b> to the location containing your <b>.ps1</b> script.</Text></li>
                        <li><Text>Click <b>"Next"</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Create a Program</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Select <b>"Do not create a program"</b> initially, then click <b>"Next"</b> and <b>"Close"</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Add a Program to the Package</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Right-click the package you created and choose <b>"Create Program"</b>.</Text></li>
                        <li><Text>Enter a <b>name</b> for the program.</Text></li>
                        <li><Text>In the <b>Command line</b> field, enter the command to run your PowerShell script:</Text></li>
                    </ul>
                    <pre css={css`background-color: var(--color-black-10); padding: 10px; margin-left: 30px; width: fit-content;`}>
                        <code>powershell.exe -ExecutionPolicy Bypass -File "your-script.ps1"</code>
                    </pre>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Set any other required parameters (e.g., <b>run mode</b>, <b>user interaction</b>).</Text></li>
                        <li><Text>Click <b>"Next"</b> and <b>"Close"</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Deploy the Package</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Go to <b>"Software Library"</b> {`>`} <b>"Application Management"</b> {`>`} <b>"Packages"</b>.</Text></li>
                        <li><Text>Right-click your package and select <b>"Deploy"</b>.</Text></li>
                        <li><Text>Select the <b>collection</b> you want to target with this deployment.</Text></li>
                        <li><Text>Choose <b>"Distribution Points"</b> and specify where to distribute the package.</Text></li>
                        <li><Text>Configure the <b>deployment settings</b> and <b>scheduling</b> as needed.</Text></li>
                        <li><Text>Click <b>"Next"</b> until you finish the wizard.</Text></li>
                    </ul>
                </li>
            </ol>
            <Text>Your PowerShell script will now be deployed and executed on the assigned devices.</Text>
        </div>
    )
}

export default MicrosoftSCCM;