/** @jsxImportSource @emotion/react */
import React from 'react';
import { graphql } from '../gql';
import { useGraphQL } from '../hooks';
import { PSLoadingScreen } from '../ui-kit';

const initialLoaderQuery = graphql(`
    query initialLoaderQuery {
        queryOpensearchDataRetentionDate {
            date
        }
        getOnboardingSecurityApproachStatus
    }
`)

type IProps = {
    children: React.ReactNode;
};

const InitialLoader: React.FC<IProps> = (props) => {
    const { children } = props;

    const {data} = useGraphQL({
        document: initialLoaderQuery,
        onSuccess(data) {
            localStorage.setItem('dataRetention', data.queryOpensearchDataRetentionDate?.date);
            if (data.getOnboardingSecurityApproachStatus) localStorage.setItem('onboardedSecurityApproach', data.getOnboardingSecurityApproachStatus);

        },
    });

    if (!data) return <PSLoadingScreen />;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default InitialLoader;