/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { TextStyle, TextStyleGlobal } from './Text.css';
import { ColorThemeKeys } from '../../styles';
import { SerializedStyles } from '@emotion/react';
import Tooltip from '../Tooltip/Tooltip';
import clsx from 'clsx';

export type TVariant = keyof typeof TextStyle;

interface IProps {
    variant?: TVariant;
    children?: React.ReactNode;
    color?: ColorThemeKeys;
    inline?: boolean;
    ellipsis?: boolean;
    textCss?: SerializedStyles;
    className?: string;
    pre?: boolean;
    onClick?: () => void;
    showUnderlineOnClick?: boolean;
    tooltipMaxCharacters?: number;
    tooltipMaxWidth?: number;
    tooltipDisableInteractive?: boolean;
    disableTooltip?: boolean;
    dataText?: string;
}

const Text: React.FC<IProps> = React.memo((props) => {
    const {
        children,
        variant = 'text',
        color,
        inline = false,
        ellipsis = false,
        pre = false,
        textCss,
        className,
        tooltipMaxCharacters,
        tooltipMaxWidth,
        tooltipDisableInteractive = true,
        onClick,
        showUnderlineOnClick,
        disableTooltip,
        dataText,
    } = props;

    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const resizeTimeoutRef = useRef<number | null>(null);
    const childrenIsElement = typeof children === 'object' && !Array.isArray(children);

    const checkOverflow = useCallback(() => {
        const textElement = textRef.current;
        if (textElement && ellipsis) {
            const isTextOverflowing = textElement.offsetWidth < textElement.scrollWidth;
            setIsOverflowing(isTextOverflowing);
        }
    }, [ellipsis]);

    const debouncedCheckOverflow = useCallback(() => {
        if (resizeTimeoutRef.current !== null) {
            cancelAnimationFrame(resizeTimeoutRef.current);
        }
        resizeTimeoutRef.current = requestAnimationFrame(() => {
            checkOverflow();
            resizeTimeoutRef.current = null;
        });
    }, [checkOverflow]);

    useEffect(() => {
        if (!ellipsis) {
            setIsOverflowing(false);
            return;
        }

        const textElement = textRef.current;
        if (!textElement) return;

        const resizeObserver = new ResizeObserver(() => {
            debouncedCheckOverflow();
        });

        resizeObserver.observe(textElement);
        checkOverflow();

        return () => {
            resizeObserver.disconnect();
            if (resizeTimeoutRef.current !== null) {
                cancelAnimationFrame(resizeTimeoutRef.current);
            }
        };
    }, [ellipsis, checkOverflow, debouncedCheckOverflow]);

    const textStyle = {
        ...(color && { color: `var(--color-${color})` }),
        ...(inline && { display: 'inline' }),
        ...(ellipsis && {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        ...(pre && {
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
        } as React.CSSProperties),
    };

    const DefaultText = (
        <div
            className={clsx('text', className)}
            ref={textRef}
            css={[
                TextStyle[variant],
                textStyle,
                textCss,
                onClick && TextStyleGlobal[showUnderlineOnClick ? 'onClickUnderline' : 'onClick'],
            ]}
            onClick={onClick}
            data-text={dataText}
        >
            {children}
        </div>
    )

    const ElementChildren = (
        <div style={{display: 'flex', gap: '2px'}}>
            {DefaultText}
            {childrenIsElement && isOverflowing && ellipsis &&
                <Text {...props}>...</Text>
            }
        </div>
    )

    if (!childrenIsElement && disableTooltip) return DefaultText;
    if (childrenIsElement && disableTooltip) return ElementChildren;

    return (
        <Tooltip
            disableInteractive={tooltipDisableInteractive}
            maxCharacters={tooltipMaxCharacters}
            maxWidth={tooltipMaxWidth}
            placement="top"
            title={isOverflowing ? children : ''}
            disableHoverListener={!isOverflowing}
        >
            {childrenIsElement ? ElementChildren : DefaultText}
        </Tooltip>
    );
});

Text.displayName = 'Text';

export default Text;