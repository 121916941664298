/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { TextTypeStyle } from './TextType.css';
import { TooltipProps } from '@mui/material';
import { Tooltip } from '../../..';
import Icon from '../../../Icon/Icon';
import Text from '../../../Text/Text';

type IProps = {
    iconOnly?: boolean;
} & Partial<CustomCellRendererProps>;

const TextType: React.FC<IProps> = (props) => {
    const { value, iconOnly = true } = props;

    const tooltipProps: Partial<TooltipProps> = useMemo(() => ({
        placement: 'right',
        disableInteractive: true,
        disableFocusListener: !iconOnly,
        disableHoverListener: !iconOnly,
        disableTouchListener: !iconOnly
    }), [iconOnly]);

    switch (value) {
        case 'Prompt':
            return <Tooltip {...tooltipProps} title="Prompt">
                <div css={TextTypeStyle.self}>
                    <Icon iconSize={20} color="black-40" iconName='PSReplyAllRightIcon' />
                    {!iconOnly && <Text>Prompt</Text>}
                </div>
            </Tooltip>
        case 'Response':
            return <Tooltip {...tooltipProps} title="Response">
                <div css={TextTypeStyle.self}>
                    <Icon iconSize={20} color="black-40" iconName='PSReplyAllLeftIcon' />
                    {!iconOnly && <Text>Response</Text>}
                </div>
            </Tooltip>

        default:
            return null;
    }
}

export default TextType;