/** @jsxImportSource @emotion/react */
import {FC, useRef} from "react";
import { PlusNStyle } from './PlusN.css';
import { usePlusN } from "../../../../hooks";
import {Text, Tooltip} from "../../../index";

type IProps = {
    value: string[];
    maxWidth: number;
    minWidth?: number;
}

const PlusN: FC<IProps> = (props) => {
    const {value, maxWidth, minWidth} = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const {
        isLimitCalculated,
        entitiesToDisplay,
        entitiesToHide,
    } = usePlusN({entities: value, containerRef})

    if (!value?.length) return null;

    return (
        <div css={PlusNStyle.self(maxWidth, minWidth)} ref={containerRef}>
            {isLimitCalculated && entitiesToDisplay && <Text>{entitiesToDisplay?.join(', ')}</Text>}
            {entitiesToHide?.length > 0 &&
                (<Tooltip
                    title={<Text>{entitiesToHide?.join(', ')}</Text>}
                    placement='top'
                    arrow
                >
                    <span>{`+${entitiesToHide.length}`}</span>
                </Tooltip>)}
        </div>
    )
}

export default PlusN;